export class ApiError {
  name: string;
  message: string;
  code: string;
  stack?: string;

  constructor (axiosData: any) {
    this.message = axiosData.message;
    this.name = axiosData.error;
    this.code = 'EAPI';
    this.stack = JSON.stringify(axiosData.stack);
  }
}

// ---------------------------------

export interface PublicPhoto {
  id: number,
  thumb: string,
  big: string,
  orig: string,
}

export interface Photo {
  id: number,
  dir: number,
  thumb: string,
  big: string,
  orig: string,
  deleted: number,
  main: number,
  source: number,
  created_at: Date,
  updated_at: Date,
}

// ---------------------------------

export interface SpeeddatingCafe {
  readonly id: number,
  name: string,
  metro: string,
  address: string,
  map: string,
  description: string,
  how_to_pass: string,
  photos?: Array<Photo>,
}

export const speeddatingCafeObject: SpeeddatingCafe = {
  id: 0,
  name: '',
  metro: '',
  address: '',
  map: '',
  description: '',
  how_to_pass: '',
};

// ---------------------------------

export interface SpeeddatingEventNoDep {
  readonly id: number,
  readonly speeddating_cafe_id: number,
  date: string, // data todo
  description: string,
  old_price_m: number,
  old_price_f: number,
  price_m: number,
  price_f: number,
  age_group: number,
  min_age_m: number,
  max_age_m: number,
  min_age_f: number,
  max_age_f: number,
  info: string,
  status: 'published' | 'canceled' | 'hidden',
}

export interface SpeeddatingEvent extends SpeeddatingEventNoDep {
  speeddating_cafe?: SpeeddatingCafe,
  speeddating_requests?: SpeeddatingRequestNoDep[],
}

export const speeddatingEventObject: SpeeddatingEvent = {
  id: 0,
  speeddating_cafe_id: 0,
  date: '',
  description: '',
  old_price_m: 0,
  old_price_f: 0,
  price_m: 0,
  price_f: 0,
  age_group: 0,
  min_age_m: 0,
  max_age_m: 0,
  min_age_f: 0,
  max_age_f: 0,
  info: '',
  status: 'hidden',
};

interface _CallTableRecord {
  date: string,
  record_type: 'call' | 'request',
  status_call: 'phone_off' | 'na' | 'denial' | 'agreement',
  status_day: 'new' | 'na' | 'confirmed' | 'canceled',
  status_evt: 'waiting' | 'confirmed' | 'canceled' | 'late' | 'na',
  price: 0,
  comment: string
}


export interface SpeeddatingUserEditable {
  display_name: string,
  tel: string,
  email: string,
  birth: string,
  age: number,
  gender: 'm' | 'f' | 'u',
}


export interface SpeeddatingRequestEditable {
  speeddating_event_id?: number,
  name?: string,
  tel?: string,
  email?: string,
  gender?: 'm' | 'f' | 'u',
  price?: number,
  status_reg?: 'new' | 'na' | 'confirmed' | 'canceled',
  status_day?: 'new' | 'na' | 'confirmed' | 'canceled',
  status_evt?: 'waiting' | 'confirmed' | 'canceled' | 'na' | 'late',
  comment_km?: string,
  comment_kv?: string,
  code?: string
}

export const SpeeddatingRequestEditableProperties: Array<keyof SpeeddatingRequestEditable> = [
  'speeddating_event_id',
  'name',
  'tel',
  'email',
  'gender',
  'price',
  'status_reg',
  'status_day',
  'status_evt',
  'comment_km',
  'comment_kv',
  'code',
];

export const mapSpeeddatingRequestToEditable = (src: SpeeddatingRequest): SpeeddatingRequestEditable => {
  return SpeeddatingRequestEditableProperties.reduce((obj, key) => {
    obj[key] = src[key] as any;
    return obj;
  }, {} as SpeeddatingRequestEditable);
};

export interface CallAnswer {
  status: 'phone_off' | 'na' | 'denial' | 'agreement',
  call_comment: string,
  manager_comment: string,
  speeddating_event_id: number,
  price: number | null,
}

export interface CallFilter {
  display_name?: string,
  gender?: 'm' | 'f' | 'u' | '',
  tel?: string,
  email?: string,
  age_from?: number,
  age_to?: number,
}

export interface UserListFilter {
  'display_name'?: string;
  'gender'?: 'm' | 'f' | 'u' | '';
  'tel'?: string;
  'email'?: string;
  'age.from'?: number;
  'age.to'?: number;
  'birth.y'?: number;
  'birth.m'?: number;
  'birth.d'?: number;
  'is_agency_client'?: number;
  'is_speeddating_client'?: number;
  'speeddating_manager_id'?: number;
  'hidden_candidate'?: number;
  'hidden_call'?: number;
  'agency_client.total_contracts.from'?: number;
  'agency_client.total_contracts.to'?: number;
  'agency_client.manager_id'?: number;
  'agency_client.status'?: 'none' | 'request' | 'phone' | 'meeting' | 'contract' | 'finished' | 'cancelled';
  'user_profile.height.from'?: number;
  'user_profile.height.to'?: number;
  // "user_profile.weight"?: number;
  // "user_profile.hair_color"?: string;
  // "user_profile.eyes_color"?: string;
  // "user_profile.body_type"?: string;
  'user_profile.birth_location'?: string;
  'user_profile.city'?: string;
  // "user_profile.district"?: string;
  // "user_profile.tattoo"?: number;
  // "user_profile.piercing"?: number;
  // "user_profile.nationality"?: string;
  // "user_profile.religion"?: string;
  // "user_profile.martial_status"?: string;
  'user_profile.children'?: number;
  // "user_profile.wait_children"?: number;
  // "user_profile.housing_type"?: string;
  'user_profile.housing_ownership'?: number;
  'user_profile.auto'?: number;
  // "user_profile.driver_license"?: number;
  // "user_profile.auto_model"?: string;
  // "user_profile.cohabitant"?: string;
  // "user_profile.education"?: string;
  // "user_profile.field_of_activity"?: string;
  // "user_profile.company_position"?: string;
  'user_profile.own_business'?: number;
  // "user_profile.own_business_area"?: string;
  // "user_profile.income"?: number;
  // "user_profile.has_pets"?: number;
  // "user_profile.hobby"?: string;
  // "user_profile.smoking"?: number;
  // "user_profile.foreign_languages"?: number;
  // "user_profile.vegan"?: number;
  // "user_profile.attitude_to_art"?: number;
  // "user_profile.attitude_to_travelling"?: number;
  // "user_profile.attitude_to_pets"?: number;
  // "user_profile.attitude_to_sport"?: number;
  // "user_profile.attitude_to_smoking"?: number;
  // "user_profile.attitude_to_alcohol"?: number;
  // "user_profile.attitude_to_drugs"?: number;
  // "user_profile.searching_for"?: string;
  // "user_profile.about"?: string;

}

export interface AgencyClient {
  readonly id: number,
  readonly user_id: number,
  tel: string | null,
  email: string | null,
  manager_id: number | null,
  readonly status: 'contract',
  readonly total_contracts: number,
  info: string | null,
  readonly created_at: Date,
  readonly updated_at: Date,
  manager?: any,
}

export interface AgencyClientTask {
  'id': number,
  'user_id': number,
  'date': string | Date,
  'target_users': number,
  'target_found': number,
  'ends': string | Date,
  'active': number,
}

export interface AgencyClientMatch {
  'id': number,
  'user_id': number,
  'match_id': number,
  'status': 'none' | 'candidate' | 'not_interested' | 'phones' | 'planning' | 'meeting' | 'dating' | 'wedding' | 'dislike',
  'date': Date,
  'comment': string,
  target: UserProfileSmall,
}

export interface AgencyClientStatus {
  'id': number,
  'user_id': number,
  'date': Date,
  'status': string,
  'price': number,
  'comment': string,
}

export interface AgencyClientMatchEditable {
  'match_id': number,
  'status': 'none' | 'candidate' | 'not_interested' | 'phones' | 'planning' | 'meeting' | 'dating' | 'wedding' | 'dislike',
  'comment': string,
}

export interface AgencyClientStatusEditable {
  'status': string,
  'price': number,
  'comment': string,
}

export interface AgencyClientTaskEditable {
  'target_users': number,
  'date': string,
  'ends': string,
}

export interface Region {
  'id': number;
  'name': string;
}


export interface UserProfileSmall {
  id: number;
  display_name: string;
  tel: string;
  email: string | null;
  gender: 'm' | 'f' | 'u';
  birth: Date;
  birth_year: number;
  age: number;
  is_agency_client: number;
  is_speeddating_client: number;
  hidden_scroller: number;
  hidden_cabinet: number;
  hidden_candidate: number;
  deleted: number;
  photos: Array<Photo>,
}

export interface UserProfile {
  height: number | null;
  weight: number | null;
  hair_color: string | null;
  eyes_color: string | null;
  body_type: string | null;
  birth_location: string | null;
  city: string | null;
  district: string | null;
  tattoo: number | null;
  piercing: number | null;
  nationality: string | null;
  religion: string | null;
  martial_status: string | null;
  children: number | null;
  children_data: Array<{ birth_year: number, gender: 'm' | 'f' }> | null,
  wait_children: number | null;
  housing_type: string | null;
  housing_ownership: number | null;
  auto: number | null;
  driver_license: number | null;
  auto_model: string | null;
  cohabitant: string | null;
  education: string | null;
  field_of_activity: string | null;
  company_position: string | null;
  own_business: number | null;
  own_business_area: string | null;
  income: number | null;
  has_pets: number | null;
  pets: string[] | null;
  hobby: string | null;
  smoking: number | null;
  foreign_languages: number | null;
  foreign_languages_what: string[] | null;
  vegan: number | null;
  attitude_to_art: number | null;
  attitude_to_travelling: number | null;
  attitude_to_pets: number | null;
  attitude_to_sport: number | null;
  attitude_to_smoking: number | null;
  attitude_to_alcohol: number | null;
  attitude_to_drugs: number | null;
  searching_for: string | null;
  about: string | null;
}

export interface SpeeddatingRequestNoDep {
  readonly id: number,
  speeddating_event_id: number,
  user_id: number,
  name: string,
  tel: string,
  email: string,
  age: number | null,
  gender: 'm' | 'f' | 'u',
  price: number,
  paid_online: number,
  paid_cash: number,
  status: 'new' | 'na' | 'confirmed' | 'canceled',
  status_reg: 'new' | 'na' | 'confirmed' | 'canceled',
  status_day: 'new' | 'na' | 'confirmed' | 'canceled',
  status_evt: 'waiting' | 'confirmed' | 'canceled' | 'na' | 'late',
  code: string,
  comment_km: string,
  comment_kv: string,
  manager_id: number | null;
}

export interface SpeeddatingRequest extends SpeeddatingRequestNoDep {
  speeddating_event?: SpeeddatingEvent
}

export interface SpeeddatingCall {
  id: number;
  user_id: number;
  manager_id: number | null;
  speeddating_request_id: number | null;
  status: 'phone_off' | 'na' | 'denial' | 'agreement',
  date: string;
  comment: string;
}

interface UserCallTable {
  date: string,
  record_type: 'call' | 'request',
  status_call: 'phone_off' | 'na' | 'denial' | 'agreement',
  status_day: 'new' | 'na' | 'confirmed' | 'canceled',
  status_evt: 'waiting' | 'confirmed' | 'canceled' | 'late' | 'na',
  price: 0,
  comment: string
}

export interface User {
  readonly id: number;
  display_name: string;
  tel: string;
  email: string | null;
  gender: 'm' | 'f' | 'u';
  birth: string | null;
  birth_year: number | null;
  age: number | null;
  is_agency_client: number;
  is_speeddating_client: number;
  is_expo_user: number;
  speeddating_manager_id: number | null;
  hidden_scroller: number;
  hidden_cabinet: number;
  hidden_candidate: number;
  hidden_call: number;
  deleted: number;
  photos?: Array<Photo>,
  user_profile?: UserProfile,
  agency_client?: AgencyClient | null,
  agency_client_statuses?: AgencyClientStatus[],
  agency_client_tasks?: AgencyClientTask[],
  agency_client_matches?: AgencyClientMatch[],
  speeddating_requests?: SpeeddatingRequest[],
  speeddating_calls?: SpeeddatingCall[],
  regions?: Region[],
  table?: UserCallTable[],
  total_events_visited?: number;
  total_events_skipped?: number;
  admin_privilege?: any,
}

export interface SpeeddatingRequestWithUser extends SpeeddatingRequest {
  user?: User;
}

export interface Invoice {
  id: number;
  user_id: number;
  description: string;
  rel_target: string;
  rel_id: number | null;
  amount: number;
  finished: number;
  receipt: string | null;
  exec_after_finished: any;
  created_at: string;
  updated_at: string;
  user: User
}

export interface InvoiceSearchFilter {
  target?: 'event' | 'contact_request' | 'free';
  finished: string; // 0|1|null
  user_id?: number;
  user: {
    display_name?: string;
    tel?: string;
    email?: string;
  }
}

// type NoNullFields<Ob> = { [K in keyof Ob]: NonNullabnle<Ob[K]> };
export type OnlyStrings<Ob> = { [K in keyof Ob]: string }
export type OnlyStrings2<Ob> = { [K in keyof Ob]: Exclude<Ob[K], null | undefined> extends object ? Ob[K] : string }

export type OnlyStringsRecursive<Ob> = { [K in keyof Ob]: Exclude<Ob[K], null | undefined> extends object ? OnlyStringsRecursive<Ob[K]> : string };

export type PartialDeep1<Ob> = { [K in keyof Ob]: Ob[K] extends object ? PartialDeep<Ob[K]> : Partial<Ob[K]> };
export type PartialDeep<T> = { [propertyKey in keyof T]?: PartialDeep<T[propertyKey]>; };

export interface UserProfileEditable_ extends OnlyStrings<Omit<UserProfile, 'children_data' | 'pets' | 'foreign_languages_what'>> {
  children_data: NonNullable<UserProfile['children_data']>;
  pets: NonNullable<UserProfile['pets']>;
  foreign_languages_what: NonNullable<UserProfile['foreign_languages_what']>;
}

export interface UserEditable extends Omit<User, 'user_profile' | 'agency_client' | 'regions'> {
  user_profile: UserProfileEditable_;
  agency_client?: AgencyClient | null;
  regions?: string[];
}

export interface SpeeddatingRequestWithUserEditable extends OnlyStrings2<SpeeddatingRequestWithUser> {
}

export interface UserListFilterEditable extends OnlyStringsRecursive<UserListFilter> {
}

export interface InvoiceEditable extends OnlyStringsRecursive<Invoice> {
}
