import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getLoginState } from '../reducers/login';
import { performFetchManagerCallStats, selectManagerCallStats } from '../reducers/managerCallStats';
import _ManagerName from '../components/_ManagerName';
import { AppDispatch } from '../store';


export default function UserEditor() {
    const dispatch = useDispatch<AppDispatch>();

    const stats = useSelector(selectManagerCallStats);
    const loginState = useSelector(getLoginState);

    useEffect(() => {
        // On reload: reset data
        dispatch(performFetchManagerCallStats());
    }, []);

    // noinspection JSUnfilteredForInLoop
    const data = useMemo(() => {
        const res: Record<number, any> = {};
        stats.items.forEach(item => {
            if (undefined === res[item.manager_id]) res[item.manager_id] = {};
            if (undefined === res[item.manager_id][item.month_start]) res[item.manager_id][item.month_start] = {}

            res[item.manager_id][item.month_start][item.status] = item.count;
        });

        for (let manager in res) for (let date in res[manager]) {
            res[manager][date].total = (Object.values(res[manager][date]) as number[]).reduce((p, c) => p + c, 0);
        }

        console.log(res);
        return res;
    }, [stats.items])

    if (!loginState.admin_privilege.km) return (<>Вам сюда нельзя</>);

    return (
        <>
            <h1>Статы по исходящим звонкам</h1>

            {Object.keys(data).map((managerId) => (
                <div key={managerId} className={"grid grid-cols-12 gap-2"}>
                    <Link className={"block"} to={`/stats/calls/manager/${managerId}`}>
                        <_ManagerName mgr_id={+managerId}/>
                    </Link>
                    {Object.keys(data[+managerId]).map(date => (<>{date}</>))}
                </div>

            ))}
        </>)
}
