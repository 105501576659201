import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Error from '../components/error';
import { performExpoAccessListFetch, selectExpoAccessList } from '../reducers/expo';
import { getLoginState } from '../reducers/login';
import { performUserUpdateByIdNew, selectUserEditorState } from '../reducers/userEditor';
import { AppDispatch } from '../store';

export default function AgencyExpoAccess () {
  const dispatch = useDispatch<AppDispatch>();

  const expoAccessList = useSelector(selectExpoAccessList);
  const loginState = useSelector(getLoginState);
  const userEditorState = useSelector(selectUserEditorState);

  const onReload = () => {
    dispatch(performExpoAccessListFetch());
  };

  useEffect(() => {
    onReload();
  }, []);

  const genPassword = (): string => {
    const charset = 'abcdefghijklmnopqrstuvwxyz0123456789';
    const charsetLength = charset.length;

    let pass: string = '';
    for (let i = 0; i < 12; i++) {
      pass += charset.charAt(Math.floor(Math.random() * charsetLength));
    }

    return pass;
  };


  const [pass, setPass] = useState<Record<number, string>>({});

  const resetPassword = (userId: number) => {
    const newPassword = pass[userId] || genPassword();

    dispatch(performUserUpdateByIdNew({
      id: userId,
      data: {
        pass: genPassword(),
      },
    }));

    setPass({ ...pass, [userId]: newPassword });
  };

  useEffect(() => {
    if (userEditorState.status === 'complete') {
      alert(`Данные для входа: Логин: +${userEditorState.data.tel}, Пароль: ${pass[userEditorState.data.id]}`);
    } else if (userEditorState.status === 'failed') {
      alert(`Не удалось поменять: ${userEditorState.error}`);
    }
  }, [userEditorState.status]);


  if (!loginState.admin_privilege.userEdit) return (<>Вам сюда нельзя</>);

  return (
    <>
      <h1>Каталог брачного агентства</h1>

      <div className={'expo-access w-container'}>
        <h2>Доступы</h2>
        {expoAccessList.state === 'fetching' ? (<>"(...обновление...)"</>) : null}
        {expoAccessList.error ? <Error error={expoAccessList.error}/> : null}
        {expoAccessList.items && expoAccessList.items.length && (<>
          <div className={'grid grid-cols-12 gap-2 font-bold'}>
            <div className={'col-span-2'}>Имя</div>
            <div className={'col-span-2'}>Телефон</div>
            <div className={'col-span-4'}>Доступ к каталогу</div>
            <div className={'col-span-4'}>Действия</div>
          </div>
          <>
            {expoAccessList.items.map((item) => (<div className={'grid grid-cols-12 gap-2 hover:bg-gray-200'}>
              <div className={'col-span-2'}>{item.user.display_name}</div>
              <div className={'col-span-2'}>+{item.user.tel}</div>
              <div className={'col-span-4'}>{item.access_till === null
                ? 'Неограниченно'
                : `До ${moment(item.access_till).format('D.M.Y')} (Еще дней: ${moment(item.access_till).diff(moment(), 'days')})`}</div>
              <div className={'col-span-4'}>
                <a href={`/users/${item.user.id}/view`} className={'btn small'}>Профиль</a>
                <a href={`/agency/${item.user.id}/view`} className={'btn small'}>Профиль БА</a>
                <a className={'btn small yellow cursor-pointer'} onClick={() => resetPassword(item.user.id)}>Сбросить
                  пароль</a>
              </div>
            </div>))}
          </>
        </>)}
      </div>
    </>);
}
