import { ApiError } from '../types/spedddating';

export const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};

type TApiSuccessAnswer<T> = {
  ok: true;
  response: T;
}

type TApiErrorAnswer = {
  ok: true;
  error: string;
}

export type TApiAnswer<T> = TApiSuccessAnswer<T> | TApiErrorAnswer;

export const isApiSuccessAnswer = <T>(payload: any): payload is TApiSuccessAnswer<T> => {
  return payload.ok && payload.response !== undefined;
};
