import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { BASE_URL } from '../app.config';
import type { TRegion } from '../types/api';

export interface LoginState {
  status: 'authenticated' | 'unauthenticated' | 'processing' | 'idle' | 'failed';
  profile: {
    id: number,
    name: string
  },
  admin_privilege: {
    km: boolean,
    kv: boolean,
    cafes: boolean,
    faceControl: boolean,
    userEdit: boolean,
    agency: boolean,
    stats: boolean,
    finance: boolean,
    regions: TRegion[],
  }
}

const initialState: LoginState = {
  status: 'idle',
  profile: {
    id: 0,
    name: '',
  },
  admin_privilege: {
    km: false,
    kv: false,
    cafes: false,
    faceControl: false,
    userEdit: false,
    agency: false,
    stats: false,
    finance: false,
    regions: [],
  },
};

export const performLogin = createAsyncThunk(
  'login/login',
  async (data: { login: string, password: string }) => {
    await axios(`${BASE_URL}/auth/login`, {
      method: 'post',
      data,
      withCredentials: true,
    });
    const result = await axios(`${BASE_URL}/auth/validate`, {
      method: 'post',
      withCredentials: true,
    });
    return result.data;
  },
);

export const performLogout = createAsyncThunk(
  'login/logout',
  async (arg, thunkAPI) => {
    // const state = thunkAPI.getState() as RootState;
    const result = await axios(`${BASE_URL}/auth/login`, {
      method: 'delete',
      withCredentials: true,
    });
    return result.data;
  },
);

export const performValidate = createAsyncThunk(
  'login/validate',
  async (arg, thunkAPI) => {
    const result = await axios(`${BASE_URL}/auth/validate`, {
      method: 'post',
      withCredentials: true,
    });
    return result.data;
  },
);

const onValidateFulfilled = (payload: any, state: LoginState) => {
  // Profile data
  state.profile.id = payload.response.id;
  state.profile.name = payload.response.name;

  // Admin privileges
  state.admin_privilege.km = payload.response.admin_privilege && payload.response.admin_privilege.access_km;
  state.admin_privilege.kv = payload.response.admin_privilege && payload.response.admin_privilege.access_kv;
  state.admin_privilege.cafes = payload.response.admin_privilege && payload.response.admin_privilege.access_cafes;
  state.admin_privilege.faceControl = payload.response.admin_privilege && payload.response.admin_privilege.access_facecontrol;
  state.admin_privilege.userEdit = payload.response.admin_privilege && payload.response.admin_privilege.access_useredit;
  state.admin_privilege.agency = payload.response.admin_privilege && payload.response.admin_privilege.access_agency;
  state.admin_privilege.stats = payload.response.admin_privilege && payload.response.admin_privilege.access_stats;
  state.admin_privilege.finance = payload.response.admin_privilege && payload.response.admin_privilege.access_finance;
  state.admin_privilege.regions = payload.response.admin_privilege.regions;

  console.log(payload.response.admin_privilege);
  console.log(JSON.stringify(state.admin_privilege));
};

const onValidateFailed = (state: LoginState) => {
  state.status = 'unauthenticated';

  state.profile = { ...initialState.profile };
  state.admin_privilege = { ...initialState.admin_privilege };
};

export const counterSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(performLogin.pending, (state) => {
      state.status = 'processing';
    });
    builder.addCase(performLogin.fulfilled, (state, action) => {
      state.status = 'authenticated';
      onValidateFulfilled(action.payload, state);
    });
    builder.addCase(performLogin.rejected, (state, action) => {
      state.status = 'failed';
      onValidateFailed(state);
    });

    builder.addCase(performValidate.pending, (state, action) => {
      state.status = 'processing';
    });
    builder.addCase(performValidate.fulfilled, (state, action) => {
      state.status = 'authenticated';
      onValidateFulfilled(action.payload, state);
    });
    builder.addCase(performValidate.rejected, (state, action) => {
      state.status = 'unauthenticated';
      onValidateFailed(state);
    });

    builder.addCase(performLogout.pending, (state) => {
      state.status = 'processing';
    });
    builder.addCase(performLogout.fulfilled, (state) => {
      state.status = 'unauthenticated';
      onValidateFailed(state);
    });
  },
});

export const getLoginState = (state: RootState) => state.login;

// export const {inc, dec} = counterSlice.actions;

export default counterSlice.reducer;
