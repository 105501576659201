import React from 'react';
import { User } from '../types/spedddating';
import { useSelector } from 'react-redux';
import { selectManagerList } from '../reducers/userEditor';

export default function _ManagerName (props: { mgr?: User, mgr_id?: number | null }) {
  const managerList = useSelector(selectManagerList);

  if (props.mgr)
    return (<>{props.mgr.admin_privilege?.name || props.mgr.display_name}</>);
  if (props.mgr_id && !managerList.by_id[props.mgr_id])
    return (<>id{props.mgr_id}</>);
  if (props.mgr_id && managerList.by_id[props.mgr_id])
    return (<>{managerList.by_id[props.mgr_id].admin_privilege?.name || managerList.by_id[props.mgr_id].display_name}</>);
  if (props.mgr === undefined && props.mgr_id === null)
    return (<>Не задан</>);
  return (<>???</>);
}
