import React, { useEffect, useState } from 'react';
import { speeddatingEventObject, SpeeddatingRequest, SpeeddatingRequestWithUserEditable } from '../../types/spedddating';
import moment from 'moment';
import 'moment/locale/ru';
import {
    performSpeeddatingRequestEdit,
    selectSpeeddatingRequestEditor,
    requestReset,
    selectSpeeddatingKVRequests,
} from '../../reducers/spedddatingRequests';
import { selectSpeeddatingRequestUserEditor, userEdit } from '../../reducers/users';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { selectSpeeddatingPublicEvents } from '../../reducers/spedddatingEvents';
import Error from '../error';
import { AppDispatch } from '../../store';


export default function RequestEditorForm (props: { type: 'rec' | 'day' | 'evt', event: number }) {
  const dispatch = useDispatch<AppDispatch>();
  const requestEditor = useSelector(selectSpeeddatingRequestEditor);
  const userEditor = useSelector(selectSpeeddatingRequestUserEditor);
  const evtRequests = useSelector(selectSpeeddatingKVRequests);

  const events = useSelector(selectSpeeddatingPublicEvents);

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<SpeeddatingRequestWithUserEditable>({ defaultValues: { ...requestEditor.data } });
  const speeddatingRequest = watch();

  //  ================================= EVENT EDITOR ======================================

  const onEditorSubmit: SubmitHandler<any> = (data, error: any) => { // SpeeddatingRequest
    let errorState = false;

    if (props.type === 'evt') {
      setValue('speeddating_event_id', String(props.event));
      data.speeddating_event_id = String(props.event);

      // Validate
    }

    if (errorState) return;

    if (['editing', 'failed'].indexOf(requestEditor.status) >= 0) {
      dispatch(performSpeeddatingRequestEdit({
        id: requestEditor.id,
        data: data,
        type: props.type,
        event: data.speeddating_event_id,
      }));
    }
  };

  const onEditorReset = (e: any) => {
    e.preventDefault();
    dispatch(requestReset());
  };

  const onUserEditClick = (e: any) => {
    e.preventDefault();
    if (requestEditor.data && requestEditor.data.user) {
      dispatch(userEdit({ id: requestEditor.data.user.id, data: requestEditor.data.user }));
    }
  };

  useEffect(() => {
    // Update form from redux store
    if (requestEditor.data && (requestEditor.status === 'editing' || requestEditor.status === 'idle')) {
      // @ts-ignore
      (Object.keys(requestEditor.data) as Array<keyof SpeeddatingRequest>).forEach(key => setValue(key, requestEditor.data[key]));
    }
  }, [requestEditor, setValue]);


  // Generate code
  if (speeddatingRequest.status_evt === 'confirmed' && !speeddatingRequest.code) {
    let nextCode: string = '';

    const existingCodes = evtRequests.items.filter(item => (item.gender === speeddatingRequest.gender) && !!item.code).map(item => {
      return +item.code.slice(1);
    });
    console.log({ existingCodes });
    for (let i = 1; i < 100; i++) {
      console.log(i, existingCodes.indexOf(i));
      if (existingCodes.indexOf(i) >= 0) continue;
      nextCode = (speeddatingRequest.gender === 'm' ? 'B' : 'A') + i.toString();
      break;
    }

    setValue('code', nextCode);
  }
  if (speeddatingRequest.status_evt !== 'confirmed' && !!speeddatingRequest.code) {
    setValue('code', '');
  }

  // Set event price
  const [event, setEvent] = useState(speeddatingEventObject);
  if (+speeddatingRequest.speeddating_event_id !== +event.id) {
    // Changed event
    const newEvent = events.by_id[speeddatingRequest.speeddating_event_id];
    if (newEvent) {
      setEvent(newEvent);
      // Reset price
      setValue('price', '' + (speeddatingRequest.gender === 'f' ? newEvent.price_f : newEvent.price_m));
    }
  }

  const hasMismatch = {
    name: requestEditor.data?.name !== requestEditor.data?.user?.display_name,
    tel: requestEditor.data?.tel !== requestEditor.data?.user?.tel,
    email: requestEditor.data?.email !== requestEditor.data?.user?.email,
    any: false,
  };
  hasMismatch.any = hasMismatch.name || hasMismatch.tel || hasMismatch.email;

  return (
    <>
      <div className="w-container">
        {userEditor.status === 'disabled' ? (<>
          <h2>Редактирование заявки</h2>
          <div className="py-4">
            <span className="font-bold">{requestEditor.data?.name}</span> (+{requestEditor.data?.tel})
            <button className="px-2" onClick={onUserEditClick}>Ред.пользователя</button>
          </div>

          {hasMismatch.any ? (<div className="pb-4">
            <p className="font-bold">ВНИМАНИЕ! Есть несоответствия, возможно требуется отредактировать профиль!</p>
            {hasMismatch.name ? (<p>Указанное в заявке имя: <span className="font-bold">{requestEditor.data?.name}</span>, в профиле <span
              className="font-bold">{requestEditor.data?.user?.display_name}</span></p>) : null}
            {hasMismatch.tel ? (<p>Указанный в заявке телефон: <span className="font-bold">{requestEditor.data?.tel}</span>, в профиле <span
              className="font-bold">{requestEditor.data?.user?.tel}</span></p>) : null}
            {hasMismatch.email ? (
              <p>Указанный в заявке e-mail: <span className="font-bold">{requestEditor.data?.email}</span>, в профиле <span
                className="font-bold">{requestEditor.data?.user?.email}</span></p>) : null}
          </div>) : null}

          <form onSubmit={handleSubmit(onEditorSubmit)} onReset={onEditorReset}>
            <div className="grid grid-cols-12 gap-2">
              {/* ========================================== EVENT  ========================================== */}

              {(props.type === 'rec') || ((props.type === 'day')) ? (
                <div className="col-span-12">
                  <label htmlFor="speeddating_event_id">Мероприятие</label>
                  <select
                    id="speeddating_event_id"
                    className={errors.speeddating_event_id ? 'error' : ''}
                    {...register('speeddating_event_id', {
                      validate: (val) => !!val,
                    })}>
                    <option value="">Не задано</option>
                    {events.items.map(event => (
                      <option key={event.id}
                              value={event.id}>{moment(event.date).locale('ru').format('ddd DD.MM HH:mm')} {event.speeddating_cafe?.name}</option>
                    ))}
                  </select>
                </div>
              ) : null}

              {(props.type === 'evt') ? (
                <input type="hidden" {...register('speeddating_event_id')}/>
              ) : null}

              {/* ========================================== STATUS ========================================== */}

              {props.type === 'rec' ? (
                <div className="col-span-12">
                  <label htmlFor="status_reg">Статус заявки (рег)</label>
                  <select id="status_reg"
                          className={errors.status_reg ? 'error' : ''}  {...register('status_reg')}>
                    <option value="new">Новая</option>
                    <option value="na">Не отвечает</option>
                    <option value="confirmed">Подтверждена</option>
                    <option value="canceled">Отменена</option>
                  </select>
                </div>
              ) : null}

              {props.type === 'day' ? (
                <div className="col-span-12">
                  <label htmlFor="status_day">Статус заявки (в день)</label>
                  <select id="status_day"
                          className={errors.status_day ? 'error' : ''}  {...register('status_day')}>
                    <option value="new">Новая</option>
                    <option value="na">Не отвечает</option>
                    <option value="confirmed">Подтверждена</option>
                    <option value="canceled">Отменена</option>
                  </select>
                </div>
              ) : null}

              {props.type === 'evt' ? (
                <div className="col-span-12">
                  <label htmlFor="status_evt">Статус заявки (визит)</label>
                  <select id="status_evt"
                          className={errors.status_evt ? 'error' : ''}  {...register('status_evt')}>
                    <option value="waiting">Ожидается</option>
                    <option value="confirmed">Подтвержден</option>
                    <option value="canceled">Отменен</option>
                    <option value="late">Опаздывает</option>
                  </select>
                </div>
              ) : null}

              {/* ========================================== CODE  ========================================== */}

              {(props.type === 'rec') || ((props.type === 'day')) ? (
                <div className="col-span-12">
                  <label htmlFor="code">Код</label>
                  <input type="text" id="code" readOnly={true} {...register('code')}/>
                </div>
              ) : null}

              {props.type === 'evt' ? (
                <div className="col-span-12">
                  <label htmlFor="code">Код</label>
                  <input type="text" id="code" {...register('code')}/>
                </div>
              ) : null}

              {/* ========================================== MONEY ========================================== */}

              {(props.type === 'rec') || ((props.type === 'day')) ? (
                <div className="col-span-12">
                  <label htmlFor="price">Стоимость</label>
                  <input type="text" className={errors.price ? 'error' : ''}
                         id="price" {...register('price')} />
                </div>
              ) : null}

              {props.type === 'evt' ? (<>
                <div className="col-span-6">
                  <label htmlFor="paid_cash">Оплачено наличными</label>
                  <input type="text" className={errors.paid_cash ? 'error' : ''}
                         id="paid_cash" {...register('paid_cash')} />
                </div>
                <div className={'col-span-6'}>
                  <label>К оплате</label>
                  <div className={'my-2 font-bold text-xl'}>
                    {parseInt(requestEditor.data?.price || '0') - parseInt(requestEditor.data?.paid_online || '0')}
                  </div>
                </div>
              </>) : null}

              {/* ========================================== COMMENT ========================================== */}

              <div className="col-span-12">
                <label htmlFor="comment_km">Комментарий для менеджера</label>
                <input id="comment_km" type="text"
                       className={errors.comment_km ? 'error' : ''}  {...register('comment_km')}/>
              </div>

              {(props.type === 'rec') || ((props.type === 'day')) ? (
                <div className="col-span-12">
                  <label htmlFor="comment_kv">Комментарий для ведущего</label>
                  <input id="comment_kv" type="text"
                         className={errors.comment_kv ? 'error' : ''}  {...register('comment_kv')}/>
                </div>
              ) : null}

              {props.type === 'evt' ? (
                <div className="col-span-12">
                  <label htmlFor="comment_kv">Комментарий для ведущего</label>
                  <input id="comment_kv" type="text" readOnly={true} {...register('comment_kv')}/>
                </div>
              ) : null}

              {/* ========================================== GENDER ========================================== */}

              <div className="col-span-12">
                <div className="col-span-12">
                  <label htmlFor="gender">Пол</label>
                  <select id="gender"
                          className={errors.gender ? 'error' : ''}
                          {...register('gender', {
                            validate: (val) => val !== 'u',
                          })}>
                    <option value="u">Не задан</option>
                    <option value="m">М</option>
                    <option value="f">Ж</option>
                  </select>
                </div>
              </div>

              {/* ===================================== FORM FIELDS END ====================================== */}
            </div>
            <div className="px-4 py-3 text-right">
              <input className="btn white" type="reset" value="Reset"/>
              <input className="btn" type="submit" disabled={requestEditor.status === 'committing'}
                     value="Submit"/>
            </div>
          </form>
        </>) : null}


      </div>
      <Error error={requestEditor.error}/>
    </>
  );
}


