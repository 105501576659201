import React, { useEffect } from 'react';
import { performLogout, getLoginState } from '../reducers/login';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { AppDispatch } from '../store';

export default function Logout () {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const loginState = useSelector(getLoginState);

  if (loginState.status === 'authenticated') {
    dispatch(performLogout());
  }

  useEffect(() => {
    if (loginState.status === 'unauthenticated') {
      navigate('/', { replace: true });
      dispatch({ type: 'RESET' });
    }
  });

  return (<>
    Logging out...
  </>);
}
