import { PHOTOS_BASE_URL } from '../app.config';
import { Link } from 'react-router-dom';
import { ttBirthAge } from '../types/toText';
import React, { ReactElement } from 'react';
import { User } from '../types/spedddating';

export default function UserEditor (props: { user: User, buttons?: ReactElement, col2?: ReactElement, agency?: boolean, contacts?: { name: string, value: string }[] }) {
  const user = props.user;

  return (<div className={'w-container'}>
    {/* START USER PREVIEW */}
    <div className={'grid lg:grid-cols-12 grid-cols-4 gap-4'}>
      <div className={'col-span-4 lg:col-span-2'}>
        {user.photos && user.photos.length ? (
          <img className={'w-full'}
               src={`${PHOTOS_BASE_URL}z${user.photos[0].dir}/${user.photos[0].thumb}`}
               alt={''}/>
        ) : (
          <div className={'square w-full bg-gray-400'}/>
        )}
      </div>

      <div className={props.col2 ? 'col-span-4' : 'col-span-4 lg:col-span-8'}>
        {/* START TITLE BLOCK */}
        <div className={'grid grid-cols-12'}>
          <div className={'col-span-12'}>
                        <span className={'text-2xl pr-4'}>
                            <Link to={`/users/${user.id}/view`}>{user.display_name}</Link>
                        </span>

            {user.deleted ? (
              <span className={'bg-black text-white rounded px-2 mr-1'}>УД</span>
            ) : null}

            {user.is_agency_client ? (
              <span
                className={'bg-purple-600 text-white rounded px-2 mr-1'} title={'БА: были взаимодействия'}>БА</span>
            ) : null}

            {user.hidden_candidate ? (
              <span className={'bg-gray-600 text-white rounded px-2 mr-1'} title={'БА: Кандидат спрятан'}>БА:СП</span>
            ) : null}

            {user.is_speeddating_client ? (
              <span
                className={'bg-green-600 text-white rounded px-2 mr-1'}>СВ</span>
            ) : null}

            {user.hidden_call ? (
              <span className={'bg-gray-600 text-white rounded px-2 mr-1'} title={'Исходящий: Кандидат спрятан'}>ЗВ:СП</span>
            ) : null}

            {user.is_expo_user ? (
              <span className={'bg-yellow-600 text-white rounded px-2 mr-1'} title={'Каталог: отображается'}>КАТ</span>
            ) : null}

            <div className={'text-xs text-gray-500'}>
              {ttBirthAge(user.birth, user.age, user.birth_year)}
            </div>
          </div>
        </div>
        {/* END TITLE BLOCK */}

        <div className={'pt-2'}>
          <div><span className={'text-gray-500'}>Тел: </span>+{user.tel}</div>
          {user.email ? (<div><span className={'text-gray-500'}>e-mail: </span>{user.email}</div>) : null}
          {props.contacts ? props.contacts.map(({ name, value }, id) =>
            <div key={`user${user.id}_contact${id}`}>
              <span className={'text-gray-500'}>{name} </span>
              {value}
            </div>,
          ) : null}
        </div>
      </div>

      {props.col2 ? (
        <div className={'col-span-4'}>
          {props.col2}
        </div>
      ) : null}

      <div className={'col-span-4 lg:col-span-2 text-right'}>
        {props.buttons}
      </div>

    </div>
    {/* END USER PREVIEW */}
  </div>);

}
