import {
  TUserProfile,
  performExpoUserSet,
  selectUserPasswordResetState,
  ERequestStatus,
  performUserPasswordResetLink,
  userPasswordResetLinkReset,
} from '../../reducers/userEditor';
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect, useMemo } from 'react';
import {
  selectExpoAccessList,
  performExpoAccessListFetch,
  TExpoAccessListState,
  selectExpoAccessSaveState,
  TExpoAccessSaveState,
  performExpoAccessSave,
} from '../../reducers/expo';
import moment from 'moment';
import Error from '../../components/error';
import { AppDispatch } from '../../store';

export default function ExpoContainer (props: {
  profile: TUserProfile,
}) {
  const { profile } = props;
  const dispatch = useDispatch<AppDispatch>();

  // Visibility
  const [isEditingExpoDisplay, setIsEditingExpoDisplay] = useState<boolean>(false);

  const setExpoVisibility = (visibility: boolean) => {
    if (profile && profile.data && profile.data.id) {
      dispatch(performExpoUserSet({
          data: {
            user_id: profile.data.id,
            visible: visibility,
          },
        },
      ));
    }
  };

  // Access
  const [isEditingExpoAccess, setIsEditingExpoAccess] = useState<boolean>(false);
  const [daysAccess, setDaysAccess] = useState<string>('7');
  const validTill = useMemo(() => {
    const days = +daysAccess;
    if (isNaN(days)) {
      return { valid: false, accessTill: '' };
    }

    if (days < 0) {
      return { valid: true, accessTill: null };
    }

    if (days === 0) {
      return { valid: true, accessTill: moment('1970-01-01T00:00:00.000Z').toISOString() };
    }

    return {
      valid: true,
      accessTill: moment().add(days, 'days').set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString(),
    };
  }, [daysAccess]);

  const expoAccessList: TExpoAccessListState = useSelector(selectExpoAccessList);
  const expoAccessSaveState: TExpoAccessSaveState = useSelector(selectExpoAccessSaveState);

  const onReload = () => {
    dispatch(userPasswordResetLinkReset());
    dispatch(performExpoAccessListFetch());
  };

  const userAccess = useMemo(() => {
    const accessById: Record<number, string | null> = {};
    for (const access of expoAccessList.items) {
      accessById[access.user_id] = access.access_till;
    }

    if (!profile || !profile.data) {
      return false;
    }

    if (profile.data.id in accessById) {
      if (accessById[profile.data.id] === null) {
        return true;
      } else {
        return accessById[profile.data.id];
      }
    }

    return false;
  }, [expoAccessList.items, profile]);

  useEffect(() => {
    if (userAccess === true) {
      setDaysAccess('-1');
    }
    if (typeof userAccess === 'string') {
      const days = moment(userAccess).diff(moment(), 'days').toString();
      setDaysAccess(days);
    }
    if (userAccess === false) {
      setDaysAccess('7');
    }
  }, [userAccess, isEditingExpoAccess]);

  const giveAccess = () => {
    if (profile.data && profile.data.id && validTill.valid) {
      dispatch(performExpoAccessSave({
        user_id: profile.data.id,
        access_till: validTill.accessTill,
      }));
    }
  };

  useEffect(() => {
    if (expoAccessSaveState.state === 'idle' && isEditingExpoAccess) {
      setIsEditingExpoAccess(false);
    }
  }, [expoAccessSaveState.state, setIsEditingExpoAccess]);

  useEffect(() => {
    onReload();
  }, []);

  // Access - reset password
  const userPasswordResetState = useSelector(selectUserPasswordResetState);

  const initiatePasswordReset = () => {
    if (!props.profile.data) {
      return; // cannot reset
    }

    dispatch(performUserPasswordResetLink({ id: props.profile.data.id }));
  };

  const handleLinkEditClock = (e: any) => {
    e.target.select();
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(e.target.value);
    } else {
      return document.execCommand('copy', true, e.target.value);
    }
  };

  return (
    <div className={'w-container'}>
      {profile && profile.data && profile.data.id ? (
        <div className={'grid lg:grid-cols-12 col-span-6 gap-4'}>
          {/*EXPO DISPLAY*/}
          <div className={'col-span-6'}>
            <span className={'text-2xl'}>Каталог БА - отображение</span>
            <div>
              {!isEditingExpoDisplay && (<div onClick={() => setIsEditingExpoDisplay(true)}>
                {profile.data.is_expo_user ? (
                  <div className={'flex items-center cursor-pointer'}>
                    <div>
                      <span className="icon text-green-500">check_circle</span>
                    </div>
                    <div className={'ml-2'}>
                      Отображается в каталоге
                    </div>
                  </div>
                ) : (
                  <div className={'flex items-center cursor-pointer'}>
                    <div>
                      <span className="icon text-yellow-500">error_outline</span>
                    </div>
                    <div className={'ml-2'}>
                      Не в каталоге
                    </div>
                  </div>
                )}
              </div>)}

              {isEditingExpoDisplay && (<div className={'grid grid-cols-12'}>
                <button className="btn white col-span-6" type="reset" onClick={() => setIsEditingExpoDisplay(false)}>Отменить</button>

                {!profile.data.is_expo_user ? (
                  <button className={'btn green col-span-6'} onClick={() => setExpoVisibility(true)}>Отображать в каталоге</button>
                ) : (
                  <button className={'btn red col-span-6'} onClick={() => setExpoVisibility(false)}>Убрать из каталога</button>
                )}
              </div>)}
            </div>
          </div>

          {/*EXPO ACCESS*/}
          <div className={'col-span-6'}>
            <span className={'text-2xl'}>Каталог БА - доступ</span>

            <div>
              {!isEditingExpoAccess && (<div onClick={() => setIsEditingExpoAccess(true)}>
                {userAccess ? (
                  <div className={'flex items-center cursor-pointer'}>
                    <div>
                      <span className="icon text-green-500">check_circle</span>
                    </div>
                    <div className={'ml-2'}>
                      Имеет доступ ({userAccess === true
                      ? 'Неограниченный'
                      : `До ${moment(userAccess).format('DD.MM.YYYY')}, еще дней: ${moment(userAccess).diff(moment(), 'days')}`})
                    </div>
                  </div>
                ) : (
                  <div className={'flex items-center cursor-pointer'}>
                    <div>
                      <span className="icon text-yellow-500">error_outline</span>
                    </div>
                    <div className={'ml-2'}>
                      Нет доступа
                    </div>
                  </div>
                )}
              </div>)}
              {isEditingExpoAccess && (<div>
                <div className={'grid grid-cols-12 gap-2 mt-2 items-center'}>

                  <div className={'col-span-12'}>
                    {userPasswordResetState.status === ERequestStatus.IDLE &&
                    <button className={'btn yellow ml-0 mr-0 w-full'} onClick={() => initiatePasswordReset()}>Получить ссылку для сброса
                        пароля</button>}

                    {userPasswordResetState.status === ERequestStatus.FETCHING &&
                    <div>Получение ссылки, пожалуйста подождите</div>}

                    {userPasswordResetState.status === ERequestStatus.COMPLETE && userPasswordResetState.data !== null && <>
                        <input type={'text'}
                               value={`https://catalog.marriedandhappy.ru/restore?login=${userPasswordResetState.data.login}&token=${userPasswordResetState.data.token}`}
                               onClick={handleLinkEditClock}/>
                    </>}

                    {userPasswordResetState.status === ERequestStatus.FAILED && <Error error={userPasswordResetState.error}/>}
                  </div>

                  <div className={'col-span-6'}>
                    <label>Кол-во дней</label>
                    <input type={'text'} value={daysAccess} onChange={(e) => setDaysAccess(e.target.value)}/>
                  </div>

                  <div className={'col-span-6 mt-5'}>
                    {!validTill.valid && (<>Некорректные данные</>)}
                    {validTill.valid && validTill.accessTill === null && (<>Без ограничений</>)}
                    {validTill.valid && validTill.accessTill !== null && (<>До {moment(validTill.accessTill).format('DD.MM.YYYY')} включительно</>)}
                  </div>

                  <button className="btn white col-span-6" type="reset" onClick={() => setIsEditingExpoAccess(false)}>Отменить</button>
                  <button className="btn col-span-6" disabled={!validTill.valid} type="reset" onClick={() => giveAccess()}>Сохранить
                  </button>
                </div>

                <Error error={expoAccessSaveState.error}/>
              </div>)}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <span className={'text-2xl'}>Каталог БА - недоступен</span>
        </div>
      )}
    </div>
  );
}

