import React, { useEffect } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Nav from './components/Nav';
import AppContainer from './components/AppContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginState } from './reducers/login';
import ManagerRequest from './pages/ManagerRequest';
import ManagerEvent from './pages/ManagerEvent';
import ManagerHistory from './pages/ManagerHistory';
import ManagerCall from './pages/ManagerCalls';
import ManagerCallsMake from './pages/ManagerCallsMake';
import ManagerCallStats from './pages/ManagerCallStats';
import ManagerCallLogs from './pages/ManagerCallLogs';
import ManagerKV from './pages/ManagerKV';
import Cafes from './pages/Cafes';
import Events from './pages/Events';
import UserList from './pages/UserList';
import UserProfileView from './pages/UserProfileView';
import UserProfileAgency from './pages/UserProfileAgency';
import UserSearchAgency from './pages/UserSearchAgency';
import AgencyClientList from './pages/AgencyClientList';
import AgencyExpoAccess from './pages/AgencyExpoAccess';
import AgencyExpoList from './pages/AgencyExpoList';
import ExportProfile from './pages/ExportProfile';
import Payments from './pages/Payments';
import PaymentNew from './pages/PaymentNew';
import AdminList from './pages/AdminList';
import { fetchManagerList, selectManagerList } from './reducers/userEditor';
import { AppDispatch } from './store';

function App () {
  const loginState = useSelector(getLoginState);
  const managerList = useSelector(selectManagerList);
  const dispatch = useDispatch<AppDispatch>();

  const onReset = () => {
    dispatch({ type: 'RESET' });
  };

  useEffect(() => {
    if (managerList.status === 'idle') {
      dispatch(fetchManagerList());
    }
  }, [managerList]);

  return (
    <>
      <Nav>
        {loginState.status === 'authenticated' ? (
          <nav>
            <NavLink to="/">Домой</NavLink>
            {loginState.admin_privilege.km ? (<NavLink to="/manager/requests">Заявки</NavLink>) : null}
            {loginState.admin_privilege.km ? (<NavLink to="/manager/events">Вечеринки</NavLink>) : null}
            {loginState.admin_privilege.km ? (<NavLink to="/manager/history">История</NavLink>) : null}
            {loginState.admin_privilege.km ? (<NavLink to="/manager/calls">Исходящий</NavLink>) : null}
            {loginState.admin_privilege.kv ? (<NavLink to="/manager/kv">Ведущий</NavLink>) : null}
            {loginState.admin_privilege.cafes ? (<NavLink to="/cafes">Ред.кафе</NavLink>) : null}
            {loginState.admin_privilege.cafes ? (<NavLink to="/events">Ред.вечеринки</NavLink>) : null}
            {loginState.admin_privilege.userEdit ? (<NavLink to="/users">Ред.пользователей</NavLink>) : null}
            {loginState.admin_privilege.agency ? (<NavLink to="/agency">Брачное агентство</NavLink>) : null}
            {/*{loginState.admin_privilege.agency ? (<NavLink to="/expo">Каталог БА</NavLink>) : null}*/}
            {loginState.admin_privilege.finance ? (<NavLink to="/payments">Счета и оплаты</NavLink>) : null}
            {loginState.admin_privilege.km ? (<NavLink to="/stats/calls">Статы</NavLink>) : null}
            {loginState.status === 'authenticated' ? (<NavLink to="/logout">Выйти</NavLink>) : null}
            <button onClick={onReset}>Reset!</button>
          </nav>
        ) : (
          <nav>
            <NavLink to="/">Домой</NavLink>
            <NavLink to="/login">Вход</NavLink>
          </nav>
        )}
      </Nav>
      <AppContainer>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/logout' element={<Logout/>}/>
          <Route path='/manager/requests' element={<ManagerRequest/>}/>
          <Route path='/manager/events' element={<ManagerEvent/>}/>
          <Route path='/manager/history' element={<ManagerHistory/>}/>
          <Route path="/manager/calls" element={<ManagerCall/>}/>
          <Route path="/manager/calls/page/:page" element={<ManagerCall/>}/>
          <Route path="/manager/calls/:id" element={<ManagerCallsMake/>}/>
          <Route path="/stats/calls" element={<ManagerCallStats/>}/>
          <Route path="/stats/calls/manager/:id" element={<ManagerCallLogs/>}/>
          <Route path="/manager/kv" element={<ManagerKV/>}/>
          <Route path='/cafes' element={<Cafes/>}/>
          <Route path='/events' element={<Events/>}/>
          <Route path='/users' element={<UserList/>}/>
          <Route path='/users/:id/view' element={<UserProfileView/>}/>
          <Route path='/users/:id/export' element={<ExportProfile/>}/>
          <Route path='/agency' element={<AgencyClientList/>}/>
          <Route path='/agency/:id/view' element={<UserProfileAgency/>}/>
          <Route path='/agency/:id/search' element={<UserSearchAgency/>}/>
          <Route path='/expo/access' element={<AgencyExpoAccess/>}/>
          <Route path='/expo/list' element={<AgencyExpoList/>}/>
          <Route path='/payments' element={<Payments/>}/>
          <Route path='/payments/new/:id' element={<PaymentNew/>}/>
          <Route path='/admin/list' element={<AdminList/>}/>
        </Routes>
      </AppContainer>
    </>
  );
}

export default App;
