import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import { fetchInvoices, selectInvoices, sendInvoice } from '../reducers/payments';
import Error from '../components/error';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { InvoiceSearchFilter } from '../types/spedddating';
import { AppDispatch } from '../store';
import { getLoginState } from '../reducers/login';

export default function Payments () {
  const dispatch = useDispatch<AppDispatch>();
  const invoices = useSelector(selectInvoices);
  const loginState = useSelector(getLoginState);

  useEffect(() => {
    dispatch(fetchInvoices());
  }, []);

  const [sent, setSent] = useState<Record<number, boolean>>({});
  const sendEmailCallback = (invoiceId: number) => {
    setSent({
      ...sent,
      [invoiceId]: true,
    });
    dispatch(sendInvoice(invoiceId));
  };

  // FORM START
  const { register, handleSubmit } = useForm<InvoiceSearchFilter>();
  const invoiceFilterFormSubmitCallback = (data: InvoiceSearchFilter) => {
    dispatch(fetchInvoices(data));
  };
  // FORM END

  if (!loginState.admin_privilege.finance) return (<>Вам сюда нельзя</>);

  return (<>
    <h1>Счета и оплаты</h1>

    {/* FILTER FORM START */}
    <form className={'grid lg:grid-cols-12 grid-cols-4 gap-2'} onSubmit={handleSubmit(invoiceFilterFormSubmitCallback)}>
      <div className={'lg:col-span-12 col-span-4'}><h2>Фильтр</h2></div>

      <div className={'col-span-2'}>
        <label>Завершенные</label>
        <select {...register('finished')}>
          <option value={''}>Не задано</option>
          <option value={'0'}>Нет</option>
          <option value={'1'}>Да</option>
        </select>
      </div>

      <div className={'col-span-2'}>
        <label>Имя пользователя</label>
        <input type={'text'} {...register('user.display_name')}/>
      </div>

      <div className={'col-span-2'}>
        <label>Телефон</label>
        <input type={'text'} {...register('user.tel')}/>
      </div>

      <div className={'col-span-2'}>
        <label>E-mail</label>
        <input type={'text'} {...register('user.email')}/>
      </div>

      <div className={'col-span-2'}>
        <label>&nbsp;</label>
        <button className={'btn'} type={'submit'}>ОК</button>
      </div>
    </form>
    {/* FILTER FORM END */}

    {invoices.error ? <Error error={invoices.error}/> : null}

    {!invoices.items ? (<div className={'px-2 py-2 border-blue-600 bg-blue-200'}>
      Идет загрузка...
    </div>) : null}

    {invoices && invoices.items.length ? invoices.items.map(item => {
      const classes = cx({
        'text-yellow-500': !item.finished,
        'text-green-500': item.finished,
      });
      return (<div className={'w-container grid lg:grid-cols-12 grid-cols-4 gap-2'} key={item.id}>

        <div className={'col-span-2 flex items-center'}>
          <div>
            {!item.finished ? <span className={`icon ${classes}`}>hourglass_empty</span> : null}
            {item.finished ? <span className={`icon ${classes}`}>check_circle</span> : null}
          </div>
          <div className={`text-xl ${classes}`}>{item.amount} руб.</div>
        </div>

        <div className={'col-span-2'}>
          <Link to={`/users/${item.user_id}/view`}>
            <div className={'font-bold'}>{item.user.display_name}</div>
            <div>+{item.user.tel}</div>
            <div>{item.user.email ? item.user.email : 'e-mail отсутствует'}</div>
          </Link>
        </div>

        <div className={'col-span-4'}>
          <div><span className={'text-gray-500'}>Назначение: </span> {item.rel_target}</div>
          <div>{item.description}</div>
        </div>

        <div className={'col-span-3'}>
          <div><span className={'text-gray-500'}>Сформировано: </span> {moment(item.created_at).format('YYYY.MM.DD HH:mm:ss')} </div>
          <div><span className={'text-gray-500'}>Оплачено: </span>
            {!item.finished ? 'Нет' : null}
            {item.finished ? <>
              <span>{moment(item.created_at).format('YYYY.MM.DD hh:mm')} </span>
              {item.receipt ? <a href={item.receipt} target={'_blank'} rel="noreferrer">Чек</a> : null}
            </> : null}
          </div>
        </div>

        <div className={'col-span-1 flex items-center'}>
          <button title="Копировать ссылку для клиента" className={'icon'}
                  onClick={() => navigator.clipboard.writeText(`https://davaynasvidanie.ru/api/payment/invoice/${item.id}`)}>content_copy
          </button>
          {sent[item.id] ?
            <button title="Отправить ссылку на емейл" className={`icon text-green-500`} disabled={true}>mail_outline</button> : null}
          {!sent[item.id] ? <button title="Отправить ссылку на емейл" className={`icon`}
                                    onClick={() => sendEmailCallback(item.id)}>mail_outline</button> : null}
        </div>
      </div>);
    }) : null}
  </>);
}
