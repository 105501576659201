import React from 'react';
import { useInput } from '../../hooks/formInput';
import { useDispatch, useSelector } from 'react-redux';

import { performLogout, performLogin, getLoginState } from '../../reducers/login';
import { AppDispatch } from '../../store';

function LoginForm () {
  const { value: login, bind: bindLogin, reset: resetLogin } = useInput('');
  const { value: password, bind: bindPassword, reset: resetPassword } = useInput('');

  const dispatch = useDispatch<AppDispatch>();
  const loginState = useSelector(getLoginState);

  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>): void => {
    evt.preventDefault();

    dispatch(performLogin({ login, password }));
    resetLogin();
    resetPassword();
  };

  const handleLogout = () => {
    dispatch(performLogout());
  };

  return (
    <>
      <div>
        State: {loginState.status}
      </div>

      {loginState.status === 'authenticated' ? (
        <>
          <h1>Welcome, {loginState.profile.name}</h1>
          <button className="btn" onClick={handleLogout}>Log out</button>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <label>
              Name:
              <input
                type="text"
                {...bindLogin}
              />
            </label>
            <label>
              Password:
              <input
                type="password"
                {...bindPassword}
              />
            </label>
            <input className="btn" type="submit" value="Submit"/>
          </form>
        </>
      )}
    </>
  );
}

export default LoginForm;
