import React from 'react';

export default function Error (props: { error: string }) {
  let error = (<></>), info;
  try {
    if (props.error === '') return error;

    const e = JSON.parse(props.error);
    if (e.name && e.message) {
      error = <><span className={'font-bold'}>{e.name}</span>: {e.message}</>;
    }

    if (e.stack) {
      try {
        const stack = JSON.parse(e.stack);
        info = (<pre>{(stack as string[]).map((msg, id) => <div key={id}>{msg}</div>)}</pre>);
      } catch (e) {
        info = (<pre>{e.stack}</pre>);
      }
    }
  } catch (e) {
    error = <pre>{props.error}</pre>;
  }

  return (<div className={'border border-red-500 bg-red-200 px-2 py-2 my-1'}>{error}{info}</div>);
}
