import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reducer from '../reducers';
import { AnyAction } from 'redux';

const rootReducer = (state: any, action: AnyAction) => {
  if (action.type === 'RESET') {
    state = undefined;
  }
  return reducer(state, action);
};

export const store = configureStore({ reducer: rootReducer });

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>;

export const resetStore = {};
