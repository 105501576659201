import React, { useEffect, useMemo, useState } from 'react';
import { UserListFilter, UserListFilterEditable } from '../types/spedddating';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import qs from 'query-string';
import ReactPaginate from 'react-paginate';
import { useSelector } from 'react-redux';
import { selectManagerList, selectUserEditorList, selectUserEditorListQuery } from '../reducers/userEditor';
import { ttAgencyStatus } from '../types/toText';
import nest from '../util/nest';
import { BASE_URL } from '../app.config';

const agencyFields = {
  'agency_client.status': 'string',
  'agency_client.total_contracts.from': 'number',
  'agency_client.total_contracts.to': 'number',
  'agency_client.manager_id': 'number',
};
const additionalFields = {
  'birth.y': 'number',
  'birth.m': 'number',
  'birth.d': 'number',
  'hidden_candidate': 'number',
  'hidden_call': 'number',
  'speeddating_manager_id': 'number',
};
const profileFields = {
  'user_profile.height.from': 'number',
  'user_profile.height.to': 'number',
  'user_profile.own_business': 'number',
  'user_profile.children': 'number',
  'user_profile.auto': 'number',
  'user_profile.housing_ownership': 'number',
  'user_profile.city': 'string',
  'user_profile.birth_location': 'string',
};

export default function UserFilterForm (props: React.PropsWithChildren<{
  onUpdate: (filter: UserListFilter, page: number) => void,
  mode: 'calls' | 'global' | 'agency',
  forceFilters?: Partial<UserListFilterEditable>
}>) {
  const navigate = useNavigate();
  const location = useLocation();

  const userEditorList = useSelector(selectUserEditorList);
  const userEditorListQuery = useSelector(selectUserEditorListQuery);
  const managerList = useSelector(selectManagerList);

  const userListFilterFromEditable = (filter: UserListFilterEditable): UserListFilter => {
    const res: UserListFilter = {};

    const types: Record<keyof UserListFilterEditable, string> = {
      'display_name': 'str',
      'gender': 'str',
      'tel': 'str',
      'email': 'str',
      'age.from': 'number',
      'age.to': 'number',
      'is_agency_client': 'number',
      'is_speeddating_client': 'number',
      ...additionalFields,
      ...agencyFields,
      ...profileFields,
    };

    Object.entries(filter).forEach(([key, val]) => {
      // @ts-ignore
      if (val !== undefined && val !== '') res[key] = types[key] === 'number' ? +val : val;
    });

    return res;
  };

  interface UserListFilterEditablePlus extends UserListFilterEditable {
    _displayAgency: boolean
  }

  const { register, reset, setValue, handleSubmit, watch } = useForm<UserListFilterEditablePlus>({});
  const formValues = watch();
  const [page, setPage] = useState(0);
  const [filters, setFilters] = useState({ agency: false, additional: false, profile: false });

  const onSubmit = (d: object) => {
    const filter = userListFilterFromEditable(nest(d));
    const qss = qs.parse(location.search);
    qss.search = encodeURI(JSON.stringify(filter));
    qss.page = '0';
    navigate({ search: qs.stringify(qss) });
  };

  const onSetPage = (page: number) => {
    const qss = qs.parse(location.search);
    qss.page = '' + page;
    navigate({ search: qs.stringify(qss) });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const filterUrl = useMemo<string>(() => {
    const query = { ...nest(formValues) as any };
    if (props.mode && props.mode === 'agency') query.is_agency_client = 1;
    if (props.mode && props.mode === 'calls') query.is_speeddating_client = 1;

    const q2 = {} as any;
    Object.entries(query).filter(([, val]) => val !== '').forEach(([key, val]) => q2[key] = val);
    Object.entries(props.forceFilters || {}).forEach(([key, val]) => q2[key] = val);

    let filterString = '';
    filterString += Object.entries(q2).map(([key, val]) => {
      return 'filter[' + key.split('.').join('][') + ']=' + val;
    }).join('&');

    return filterString;
  }, [formValues, props.mode]);

  useEffect(() => {
    const qss = qs.parse(location.search);

    // Parse query string
    let query: UserListFilter = {};
    if (qss.search) {
      query = JSON.parse(decodeURI(qss.search as string));
      reset(query as UserListFilterEditable);
    }

    // Page
    let _page = 0;
    if (qss.page) _page = parseInt(qss.page as string);
    if (_page !== page) setPage(_page);

    // Reload user list
    if (props.mode && props.mode === 'agency') query.is_agency_client = 1;
    if (props.mode && props.mode === 'calls') query.is_speeddating_client = 1;

    Object.assign(query, props.forceFilters);

    props.onUpdate(query, _page);

    // Set filters status
    const agencyFilterEnabled = (props.mode && props.mode === 'agency') ? true : Object.keys(query).filter(key => key in agencyFields).length !== 0;
    const additionalFilterEnabled = Object.keys(query).filter(key => key in additionalFields).length !== 0;
    const profileFilterEnabled = Object.keys(query).filter(key => key in profileFields).length !== 0;

    setFilters({ agency: agencyFilterEnabled, additional: additionalFilterEnabled, profile: profileFilterEnabled });
  }, [location.search]);

  const toggleFilters = (what: 'agency' | 'additional' | 'profile') => {
    if (what === 'agency') {
      setFilters(filters => ({ ...filters, agency: !filters.agency }));
      Object.keys(agencyFields).forEach(field => setValue(field as keyof UserListFilterEditable, ''));
    }
    if (what === 'additional') {
      setFilters(filters => ({ ...filters, additional: !filters.additional }));
      Object.keys(additionalFields).forEach(field => setValue(field as keyof UserListFilterEditable, ''));
    }
    if (what === 'profile') {
      setFilters(filters => ({ ...filters, profile: !filters.profile }));
      Object.keys(profileFields).forEach(field => setValue(field as keyof UserListFilterEditable, ''));
    }
  };

  const YesNoSelect = (register: any) => (<select {...register}>
    <option value="">Не задано</option>
    <option value="1">Да</option>
    <option value="0">Нет</option>
  </select>);

  return (<div>
    <form onSubmit={handleSubmit(onSubmit)}>
      <h2>Фильтр</h2>

      <input type={'hidden'} {...register('is_agency_client')}/>
      <input type={'hidden'} {...register('is_speeddating_client')}/>

      <div className={'grid lg:grid-cols-12 grid-cols-4 gap-2'}>
        <div className={'col-span-2'}>
          <label>Имя</label>
          <input type={'text'} {...register('display_name')}/>
        </div>
        <div className="col-span-2">
          <label htmlFor="date">Телефон</label>
          <input id="date" type="text" {...register('tel')}/>
        </div>
        <div className="col-span-2">
          <label htmlFor="date">E-mail</label>
          <input id="date" type="text" {...register('email')}/>
        </div>
        <div className="col-span-2">
          <label htmlFor="date">Пол</label>
          <select id="date" {...register('gender')}>
            <option value="">Все</option>
            <option value="m">М</option>
            <option value="f">Ж</option>
            <option value="u">Не заданные</option>
          </select>
        </div>
        <div className={'col-span-2'}>
          <label>Возраст - от</label>
          <input type={'text'} {...register('age.from')}/>
        </div>
        <div className={'col-span-2'}>
          <label>Возраст - до</label>
          <input type={'text'} {...register('age.to')}/>
        </div>
      </div>

      <div className={'my-2'}>
        <label>
          Фильтр по профилю - доп.поля:&nbsp;
          <input type={'checkbox'} checked={filters.additional} onChange={() => toggleFilters('additional')}/>&nbsp;
        </label>
      </div>
      <div className={'grid lg:grid-cols-12 grid-cols-4 gap-2'} style={filters.additional ? {} : { display: 'none' }}>
        <div className={'col-span-2'}>
          <label>ДР - год</label>
          <input type={'text'} {...register('birth.y')}/>
        </div>
        <div className={'col-span-2'}>
          <label>ДР - месяц</label>
          <input type={'text'} {...register('birth.m')}/>
        </div>
        <div className={'col-span-2'}>
          <label>ДР - день</label>
          <input type={'text'} {...register('birth.d')}/>
        </div>
        <div className={'col-span-2'}>
          <label>Спрятанный кандидат</label>
          <select id="date" {...register('hidden_candidate')} disabled={!!props.forceFilters?.hidden_candidate}
                  value={props.forceFilters?.hidden_candidate?.toString()}>
            <option value="">Все</option>
            <option value="0">Нет</option>
            <option value="1">Да</option>
          </select>
        </div>
        <div className={'col-span-2'}>
          <label>Спрятанный звонок</label>
          <select id="date" {...register('hidden_call')} disabled={!!props.forceFilters?.hidden_call}
                  value={props.forceFilters?.hidden_call?.toString()}>
            <option value="">Все</option>
            <option value="0">Нет</option>
            <option value="1">Да</option>
          </select>
        </div>
        <div className={'col-span-2'}>
          <label>Менеджер</label>
          <select {...register('speeddating_manager_id')}>
            <option value={''}>Не задано</option>
            {managerList.items.map((manager, id) => (
              <option key={id} value={manager.id}>{manager.admin_privilege?.name || manager.display_name}</option>
            ))}
          </select>
        </div>
      </div>

      {props.mode && (props.mode === 'global' || props.mode === 'agency') ? (<>
        <div className={'my-2'}>
          <label>
            Фильтр брачного агентства:&nbsp;
            <input type={'checkbox'} checked={filters.agency} onChange={() => toggleFilters('agency')}/>&nbsp;
            <span style={filters.agency ? {} : { display: 'none' }} className={'text-red-500'}>&nbsp;
              Внимание! Будут выведены только те пользователи, у кого выбранные поля фильтра заполнены!
                    </span>
          </label>
        </div>
        <div className={'grid lg:grid-cols-12 grid-cols-4 gap-2'} style={filters.agency ? {} : { display: 'none' }}>
          <div className={'col-span-2'}>
            <label>Статус договора:</label>
            <select {...register('agency_client.status')}>
              <option value={''}>Не задано</option>
              {['none', 'request', 'contract', 'finished', 'cancelled', 'expo_free', 'expo_paid'].map(value => (
                <option value={value}>{ttAgencyStatus(value)}</option>
              ))}
            </select>
          </div>
          <div className={'col-span-2'}>
            <label>Договоров мин</label>
            <input type={'text'} {...register('agency_client.total_contracts.from')}/>
          </div>
          <div className={'col-span-2'}>
            <label>Договоров макс</label>
            <input type={'text'} {...register('agency_client.total_contracts.to')}/>
          </div>
          <div className={'col-span-2'}>
            <label>Менеджер</label>
            <select {...register('agency_client.manager_id')}>
              <option value={''}>Не задано</option>
              {managerList.items.map((manager, id) => (
                <option key={id} value={manager.id}>{manager.admin_privilege?.name || manager.display_name}</option>
              ))}
            </select>
          </div>
        </div>
      </>) : null}

      <div className={'my-2'}>
        <label>
          Фильтр по данным профиля:&nbsp;
          <input type={'checkbox'} checked={filters.profile} onChange={() => toggleFilters('profile')}/>&nbsp;
          <span style={filters.profile ? {} : { display: 'none' }} className={'text-red-500'}>&nbsp;
            Внимание! Будут выведены только те пользователи, у кого выбранные поля фильтра заполнены!
                    </span>
        </label>
      </div>
      <div className={'grid lg:grid-cols-12 grid-cols-4 gap-2'} style={filters.profile ? {} : { display: 'none' }}>
        <div className={'col-span-2'}>
          <label>Рост - от</label>
          <input type={'text'} {...register('user_profile.height.from')}/>
        </div>
        <div className={'col-span-2'}>
          <label>Рост - до</label>
          <input type={'text'} {...register('user_profile.height.to')}/>
        </div>
        <div className={'col-span-2'}>
          <label>Наличие детей</label>
          {YesNoSelect(register('user_profile.children'))}
        </div>
        <div className={'col-span-2'}>
          <label>Наличие бизнеса</label>
          {YesNoSelect(register('user_profile.own_business'))}
        </div>
        <div className={'col-span-2'}>
          <label>Свое жилье</label>
          {YesNoSelect(register('user_profile.housing_ownership'))}
        </div>
        <div className={'col-span-2'}>
          <label>Наличие автомобиля</label>
          {YesNoSelect(register('user_profile.auto'))}
        </div>
        <div className={'col-span-4'}>
          <label>Город</label>
          <input type={'text'} {...register('user_profile.city')}/>
        </div>
        <div className={'col-span-4'}>
          <label>Место рождения</label>
          <input type={'text'} {...register('user_profile.birth_location')}/>
        </div>
      </div>

      {props.mode && (props.mode === 'calls') ? <>
        <div className={'grid lg:grid-cols-12 grid-cols-4 gap-2'}>
          <div className={'col-span-2'}>
            <label>Сортировка</label>
            <select>
              <option value={'id'}>По ИД</option>
              <option value={'-id'}>По ИД (обратно)</option>
              <option value={'cd'}>По дате последнего звонка</option>
            </select>
          </div>
        </div>
      </> : null}

      <div className={'my-2 text-right'}>
        {props.mode && (props.mode === 'calls') ? (<>
          <a className={'btn cursor-pointer'}
             href={`${BASE_URL}/user/export_csv?offset=0&limit=999999&${filterUrl}`}
             target={'_blank'}
          >Экспорт CSV</a>
        </>) : null}
        <button className={'btn'} type={'submit'}>Применить фильтр</button>
      </div>
    </form>

    {props.children}

    <div className={'text-gray-600 text-sm my-2'}>Отображены
      результаты {page * userEditorListQuery.limit + 1}-{Math.min((page + 1) * userEditorListQuery.limit, userEditorList.total)} из {userEditorList.total}</div>
    {userEditorList.total !== 0 ? (
      <ReactPaginate
        forcePage={page}
        previousLabel={'<<'}
        nextLabel={'>>'}
        breakLabel={'...'}
        pageCount={Math.ceil(userEditorList.total / userEditorListQuery.limit)}
        marginPagesDisplayed={5}
        pageRangeDisplayed={5}
        containerClassName={'pagination'}
        activeClassName={'active'}
        hrefBuilder={(id) => `?page=${id}`}
        onPageChange={({ selected: id }) => onSetPage(id)}
      />
    ) : null}
  </div>);
}
