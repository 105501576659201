import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { BASE_URL } from '../app.config';
import { ApiError, User } from '../types/spedddating';


const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};

export type TExpoUserListItem = User & {
  expo_id: number;
}

export type TExpoAccessListItem = {
  id: number;
  user_id: number;
  access_till: string | null;
  user: {
    id: number;
    display_name: string;
    tel: string;
    email: string;
  }
}

export type TExpoUserListState = {
  state: 'idle' | 'fetching' | 'complete' | 'failed',
  items: Array<TExpoUserListItem>,
  error: string
}

export type TExpoAccessListState = {
  state: 'idle' | 'fetching' | 'complete' | 'failed',
  items: Array<TExpoAccessListItem>,
  error: string
}

export type TExpoAccessSaveState = {
  state: 'idle' | 'fetching' | 'failed',
  error: string;
}

interface UserReduxInitState {
  expoUserList: TExpoUserListState,
  expoAccessList: TExpoAccessListState,
  expoAccessSaveState: TExpoAccessSaveState
}

const initialState: UserReduxInitState = {
  expoUserList: {
    state: 'idle',
    items: [],
    error: '',
  },
  expoAccessList: {
    state: 'idle',
    items: [],
    error: '',
  },
  expoAccessSaveState: {
    state: 'idle',
    error: '',
  },
};

export const performExpoAccessListFetch = createAsyncThunk(
  'expo/access/get',
  async () => {
    const result = await axios(`${BASE_URL}/expo/access`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const performExpoUserListFetch = createAsyncThunk(
  'expo/user/get',
  async () => {
    const result = await axios(`${BASE_URL}/expo/list`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const performExpoAccessSave = createAsyncThunk(
  'expo/access/set',
  async (r: { user_id: number, access_till: string | null }, thunkAPI) => {
    const result = await axios(`${BASE_URL}/expo/access`, {
      method: 'post',
      withCredentials: true,
      data: {
        user_id: r.user_id,
        access_till: r.access_till,
      },
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performExpoAccessListFetch());

    return result.data;
  },
);

export const counterSlice = createSlice({
  name: 'expo',
  initialState,
  reducers: {
    // userEdit: ((state, action: PayloadAction<{id: number, data: User}>) => {
    //     state.userEditor = {
    //         status: "editing",
    //         id: action.payload.id,
    //         data: action.payload.data,
    //         error: ''
    //     }
    // }),
    // userEditReset: (state) => {
    //     state.userEditor = {
    //         status: "disabled",
    //         id: 0,
    //         data: null,
    //         error: ''
    //     }
    // },
    //
    // resetUserSearchResults: (state) => {
    //     state.userSearchResult.state = "idle";
    //     state.userSearchResult.items = [];
    //     state.userSearchResult.error = "";
    // },
  },
  extraReducers: builder => {
    // performExpoAccessListFetch
    builder.addCase(performExpoAccessListFetch.pending, state => {
      // console.log(Date.now(), "performUserSearch: pending");
      state.expoAccessList.state = 'fetching';
    });
    builder.addCase(performExpoAccessListFetch.fulfilled, (state, action) => {
      // console.log(Date.now(), "performUserSearch: fulfilled");
      if (state.expoAccessList.state !== 'fetching') return;
      state.expoAccessList.state = 'complete';
      state.expoAccessList.items = action.payload.response;
    });
    builder.addCase(performExpoAccessListFetch.rejected, (state, action) => {
      if (state.expoAccessList.state !== 'fetching') return;
      state.expoAccessList.state = 'failed';
      state.expoAccessList.error = JSON.stringify(action.error, null, 2);
    });

    // performExpoUserListFetch
    builder.addCase(performExpoUserListFetch.pending, state => {
      // console.log(Date.now(), "performUserSearch: pending");
      state.expoUserList.state = 'fetching';
    });
    builder.addCase(performExpoUserListFetch.fulfilled, (state, action) => {
      // console.log(Date.now(), "performUserSearch: fulfilled");
      if (state.expoUserList.state !== 'fetching') return;
      state.expoUserList.state = 'complete';
      state.expoUserList.items = action.payload.response;
    });
    builder.addCase(performExpoUserListFetch.rejected, (state, action) => {
      if (state.expoUserList.state !== 'fetching') return;
      state.expoUserList.state = 'failed';
      state.expoUserList.error = JSON.stringify(action.error, null, 2);
    });

    // performExpoAccessSave
    builder.addCase(performExpoAccessSave.pending, state => {
      // console.log(Date.now(), "performUserSearch: pending");
      state.expoAccessSaveState.state = 'fetching';
      state.expoAccessSaveState.error = '';
    });
    builder.addCase(performExpoAccessSave.fulfilled, (state, action) => {
      // console.log(Date.now(), "performUserSearch: fulfilled");
      if (state.expoAccessSaveState.state !== 'fetching') return;
      state.expoAccessSaveState.state = 'idle';
    });
    builder.addCase(performExpoAccessSave.rejected, (state, action) => {
      if (state.expoAccessSaveState.state !== 'fetching') return;
      state.expoAccessSaveState.state = 'failed';
      state.expoAccessSaveState.error = JSON.stringify(action.error, null, 2);
    });
  },
});

export const selectExpoUserList = (state: RootState) => state.expo.expoUserList;
export const selectExpoAccessList = (state: RootState) => state.expo.expoAccessList;
export const selectExpoAccessSaveState = (state: RootState) => state.expo.expoAccessSaveState;

// export const {userEdit, userEditReset, resetUserSearchResults} = counterSlice.actions;

export default counterSlice.reducer;
