import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { BASE_URL } from '../app.config';
import { ApiError, SpeeddatingCafe, CallAnswer, CallFilter, User } from '../types/spedddating';


const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};


interface CallsReduxInitState {
  users: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    items: Array<User>,
    error: string,
    by_id: any,
    total: number,
  },
  callingUser: {
    status: 'idle' | 'data-waiting' | 'data-complete' | 'data-saving' | 'data-saved' | 'failed',
    error: string,
    data: User | null,
  },
  callDisplay: {
    filter: CallFilter,
    offset: number,
    limit: number,
    activeCall: number | null,
  }
}

const initialState: CallsReduxInitState = {
  users: {
    status: 'idle',
    items: [],
    error: '',
    by_id: {},
    total: 0,
  },
  callingUser: {
    status: 'idle',
    error: '',
    data: null,
  },
  callDisplay: {
    filter: {},
    offset: 0,
    limit: 50,
    activeCall: null,
  },
};

// ------------------------------------------------- CAFES -------------------------------------------------------------

// noinspection DuplicatedCode
export const fetchSpeeddatingOutCalls = createAsyncThunk(
  'speeddating/outcall/get',
  async (_, { getState }) => {
    const { calls } = getState() as RootState;
    const filter = calls.callDisplay.filter;

    let filterString = `?offset=${calls.callDisplay.offset}&limit=${calls.callDisplay.limit}&`;
    if (filter) {
      filterString += Object.entries(filter)
        .map(([key, val]) => {
          if (val === '') return null;
          return `filter[${key}]=${val}`;
        })
        .filter(item => item !== null)
        .join('&');
    }

    const result = await axios(`${BASE_URL}/speeddating/outcall${filterString}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);
// noinspection DuplicatedCode
export const fetchSpeeddatingUserCall = createAsyncThunk(
  'speeddating/outcall/by_id/get',
  async (r: { id: number }) => {

    const result = await axios(`${BASE_URL}/speeddating/outcall/${r.id}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);


export const setOutCall = createAsyncThunk(
  'speeddating/outcall/by_id/post',
  async (r: { id: number, data: CallAnswer }, thunkAPI) => {
    const result = await axios(`${BASE_URL}/speeddating/outcall/${r.id}`, {
      method: 'post',
      withCredentials: true,
      data: {
        status: r.data.status,
        call_comment: r.data.call_comment,
        manager_comment: r.data.manager_comment,
        speeddating_event_id: r.data.speeddating_event_id,
        price: r.data.price,
      },
    }).catch(apiErrorHandler);

    return result.data;
  },
);

// ------------------------------------------------ EVENTS -------------------------------------------------------------


export const counterSlice = createSlice({
  name: 'cafe',
  initialState,
  reducers: {
    makeCall: ((state, action: PayloadAction<{ user: User }>) => {
      state.callingUser.data = action.payload.user;
      state.callingUser.error = '';
      state.callingUser.status = 'data-complete';
    }),
    makeCallCancel: (state) => {
      state.callingUser.data = null;
      state.callingUser.error = '';
      state.callingUser.status = 'idle';
    },
    setDisplay: ((state, action: PayloadAction<{ offset?: number, limit?: number, filter?: CallFilter, activeCall?: number, clearData?: boolean }>) => {
      state.callDisplay = {
        offset: action.payload.offset !== undefined ? action.payload.offset : state.callDisplay.offset,
        limit: action.payload.limit !== undefined ? action.payload.limit : state.callDisplay.limit,
        filter: action.payload.filter !== undefined ? { ...action.payload.filter } : { ...state.callDisplay.filter },
        activeCall: action.payload.activeCall !== undefined ? action.payload.activeCall : state.callDisplay.activeCall,
      };
      if (action.payload.clearData) {
        state.users.items = [];
      }
    }),
  },
  extraReducers: builder => {
    builder.addCase(fetchSpeeddatingOutCalls.pending, state => {
      // console.log(Date.now(), "fetchSpeeddatingCafes: pending");
      state.users.status = 'fetching';
    });

    builder.addCase(fetchSpeeddatingOutCalls.fulfilled, (state, action) => {
      // console.log(Date.now(), "fetchSpeeddatingCafes: fulfilled");
      if (state.users.status !== 'fetching') return;
      state.users.status = 'complete';
      state.users.by_id = {} as any;
      state.users.items = action.payload.response.map((cafe: SpeeddatingCafe) => {
        return cafe;
      });
      state.users.total = action.payload.total;
    });

    builder.addCase(fetchSpeeddatingOutCalls.rejected, (state, action) => {
      if (state.users.status !== 'fetching') return;
      state.users.status = 'failed';
      state.users.error = JSON.stringify(action.error, null, 2);
    });

    // ------------------------------------------- EVENTS ----------------------------------------------------------

    builder.addCase(fetchSpeeddatingUserCall.pending, state => {
      // console.log(Date.now(), "fetchSpeeddatingCafes: pending");
      state.callingUser.status = 'data-waiting';
      state.callingUser.data = null;
    });

    builder.addCase(fetchSpeeddatingUserCall.fulfilled, (state, action) => {
      // console.log(Date.now(), "fetchSpeeddatingCafes: fulfilled");
      state.callingUser.status = 'data-complete';
      state.callingUser.data = action.payload.response;
    });

    builder.addCase(fetchSpeeddatingUserCall.rejected, (state, action) => {
      state.callingUser.status = 'failed';
      state.callingUser.error = JSON.stringify(action.error, null, 2);
    });
    // ------------------------------------------- EVENTS ----------------------------------------------------------

    builder.addCase(setOutCall.pending, (state) => {
      state.callingUser.status = 'data-saving';
      state.callingUser.error = '';
    });
    builder.addCase(setOutCall.fulfilled, (state) => {
      state.callingUser.data = null;
      state.callingUser.status = 'data-saved';
      state.callingUser.error = '';
    });
    builder.addCase(setOutCall.rejected, (state, action) => {
      state.callingUser.status = 'failed';
      state.callingUser.error = JSON.stringify(action.error, null, 2);
    });

    // ------------------------------------------- EVENTS ----------------------------------------------------------

  },
});

export const selectUsers = (state: RootState) => state.calls.users;
export const selectUserCall = (state: RootState) => state.calls.callingUser;
export const selectCallDisplay = (state: RootState) => state.calls.callDisplay;
export const { makeCall, makeCallCancel, setDisplay } = counterSlice.actions;

export default counterSlice.reducer;
