import {
  performUserUpdateByIdInternational,
  performUserGetByIdInternational,
  selectUserStateIntl,
  selectUserEditorStateIntl,
} from '../../reducers/userEditor';
import React, { useEffect } from 'react';
import EditableFragment from '../../components/EditableFragment';
import { useForm, useFormState } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../../components/error';
import { AppDispatch } from '../../store';

export function LocalizationContainer (props: {
  userId: string,
}) {
  const { userId } = props;
  const dispatch = useDispatch<AppDispatch>();

  const targetLang = { value: 'en', full: 'English' };

  useEffect(() => {
    dispatch(performUserGetByIdInternational({ id: +userId, lang: targetLang.value }));
  }, [targetLang.value]);

  const { register, control, watch: profileWatch, reset, setValue } = useForm<{ d: any }>();
  const watch = profileWatch();
  const { dirtyFields } = useFormState({ control });

  const userData = useSelector(selectUserStateIntl);
  const userEditorData = useSelector(selectUserEditorStateIntl);
  useEffect(() => {
    reset({ d: userData.data });
  }, [userData.data]);

  const onSaveProfile = () => {
    dispatch(performUserUpdateByIdInternational({ id: +userId, lang: targetLang.value, data: watch.d }));
  };

  return (<>
    <div className={'w-container'}>
      <div className="grid grid-rows-1 lg:grid-cols-12 grid-cols-4 gap-8">
        <div className={'lg:col-span-12 col-span-4 text-2xl uppercase'}>Международный профиль ({targetLang.value} - {targetLang.full})
        </div>

        {userData.error ? <Error error={userData.error}/> : null}
        {userEditorData.error ? <Error error={userEditorData.error}/> : null}

        {/* PROFILE COLUMN 1 START */}
        <div className={'col-span-4'}>
          <div className={'grid grid-cols-12 gap-y-1'}>
            <div className={'col-span-6 text-gray-500 flex items-center'}>Имя</div>
            <EditableFragment containerClass={'col-span-6 text-gray-800'}
                              register={register('d.display_name')}
                              isDirty={dirtyFields.d?.display_name || false}
                              value={watch.d?.display_name || ''}
                              input={'text'}/>
          </div>
        </div>
        {/* PROFILE COLUMN 1 END */}

        {/* PROFILE BIG FIELD START */}
        <div className={'col-span-12'}>
          <span className={'text-gray-500'}>О себе: </span>
          <EditableFragment containerClass={''}
                            register={register('d.about')}
                            isDirty={dirtyFields.d?.about || false}
                            value={watch.d?.about || ''}
                            input={'textarea'}/>
        </div>
        {/* PROFILE BIG FIELD END */}

        {dirtyFields.d ? (<>
          <div className={'lg:col-span-12 col-span-4 text-right'}>
            <button className={'btn'} onClick={() => onSaveProfile()}>Сохранить</button>
          </div>
        </>) : null}

      </div>
    </div>
  </>);
}
