import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Error from '../components/error';
import { getLoginState } from '../reducers/login';
import { AppDispatch } from '../store';
import { selectAdminList, performAdminListFetch, performAdminListSave, performAdminDelete } from '../reducers/admin';
import { TAdmin, TRegion } from '../types/api';
import { Link } from 'react-router-dom';
import { fetchRegions, selectRegions } from '../reducers/regions';

const ADMIN_PRIVILEGES = {
  access_km: 'Каб.менеджера',
  access_kv: 'Каб.ведущего',
  access_cafes: 'Мероприятия',
  access_facecontrol: 'Фейсконтроль',
  access_useredit: 'Ред. пользователя',
  access_stats: 'Статы',
  access_agency: 'Брачное',
  access_agency_phones: 'Брачное телефоны',
  access_finance: 'Деньги',
};

export default function AdminList () {
  const dispatch = useDispatch<AppDispatch>();

  const loginState = useSelector(getLoginState);
  const adminList = useSelector(selectAdminList);
  const regionsSelector = useSelector(selectRegions);

  useEffect(() => {
    dispatch(performAdminListFetch());

    if (!Array.isArray(regionsSelector.data) || (regionsSelector.data.length === 0)) {
      dispatch(fetchRegions());
    }
  }, []);

  // Локальное состояние для редактируемых данных
  const [admins, setAdmins] = useState<TAdmin[]>([]);

  // Загружаем данные админов в локальное состояние при первой загрузке
  useEffect(() => {
    const adminIds = adminList.data.map(({ id }) => id);
    if ((admins.length !== 0) && (admins.length !== adminList.data.length)) {
      setAdmins((admins) => admins.filter(({ id }) => adminIds.includes(id)));
    }

    if ((admins.length === 0) && (adminList.data.length > 0)) {
      setAdmins(adminList.data.map((admin) => ({ ...admin, regions: [...admin.regions] })));
    }
  }, [adminList]);


  // Обработчик для переключения "Все регионы"
  const toggleAllRegions = (adminId: number) => {
    setAdmins((prev) =>
      prev.map((admin) =>
        admin.id === adminId
          ? { ...admin, regions: admin.regions.length === 0 ? allRegions : [] }
          : admin,
      ),
    );
  };

  // Обработчик для добавления/удаления региона
  const toggleRegion = (adminId: number, regionId: number) => {
    setAdmins((prev) =>
      prev.map((admin) =>
        admin.id === adminId
          ? {
            ...admin,
            regions: admin.regions.some((r) => r.id === regionId)
              ? admin.regions.filter((r) => r.id !== regionId)
              : [...admin.regions, allRegions.find((r) => r.id === regionId)] as TRegion[],
          }
          : admin,
      ),
    );
  };

  const saveAdminChanges = (adminId: number) => {
    const adminToSave = admins.find((admin) => admin.id === adminId);
    if (adminToSave) {
      dispatch(performAdminListSave(adminToSave));
      setAdmins((prev) =>
        prev.map((admin) =>
          admin.id === adminId ? { ...admin } : admin,
        ),
      );
    }
  };

  const deleteAdmin = (adminId: number) => {
    const adminToDelete = admins.find((admin) => admin.id === adminId);
    if (adminToDelete) {
      // eslint-disable-next-line no-restricted-globals
      if (confirm(`Удаляем админа ${adminToDelete.name}?`)) {
        dispatch(performAdminDelete({ id: adminId }));
      }
    }

  };

  const togglePrivilege = (adminId, privilege) => {
    setAdmins((prev) =>
      prev.map((admin) =>
        admin.id === adminId
          ? { ...admin, [privilege]: 1 - admin[privilege] }
          : admin,
      ),
    );
  };


  if (!loginState.admin_privilege.userEdit) return (<>Вам сюда нельзя</>);

  const allRegions: TRegion[] = regionsSelector.data || [];

  const isAdminModified = (adminToCheck: number): boolean => {
    const NO_CHECK = ['user', 'updated_at'];

    const saved = adminList.data.find(({ id }) => id === adminToCheck);
    const editing = admins.find(({ id }) => id === adminToCheck);

    if (!saved || !editing) {
      return false; // Если данные администратора отсутствуют, считаем его неизменённым
    }

    // Проверяем все поля в `editing`, отличаются ли они от `saved`
    return Object.keys(editing).some((key) => {
      if (NO_CHECK.includes(key)) return false;

      const valueEditing = editing[key as keyof typeof editing];
      const valueSaved = saved[key as keyof typeof saved];

      // Для массивов (например, `regions`) сравниваем содержимое
      if (Array.isArray(valueEditing) && Array.isArray(valueSaved)) {
        const a = valueEditing.map(({ id }) => id).sort();
        const b = valueSaved.map(({ id }) => id).sort();

        return JSON.stringify(a) !== JSON.stringify(b);
      }

      return valueEditing !== valueSaved;
    });
  };

  return (
    <>
      <h1>Админы</h1>

      <div className={'expo-access w-container'}>
        <h2>Доступы</h2>
        {adminList.loading && (<>"(...обновление...)"</>)}
        {adminList.error && <Error error={adminList.error}/>}
        {adminList.data && Array.isArray(adminList.data) && (<>
          <div className={'grid grid-cols-12 gap-2 font-bold'}>
            <div className={'col-span-2'}>Имя</div>
            <div className={'col-span-2'}>Имя профиля</div>
            <div className={'col-span-3'}>Доступы</div>
            <div className={'col-span-3'}>Регионы</div>
            <div className={'col-span-2'}>Действия</div>
          </div>

          <>
            {admins.map((item, id) => (
              <div className={'grid grid-cols-12 gap-2 hover:bg-gray-200 pb-4'} key={`admin_${id}`}>
                <div className={'col-span-2'}>{item.name}</div>
                <div className={'col-span-2'}>
                  <Link to={`/agency/${item.user.id}/view`}>
                    <div className={'font-bold'}>{item.user.display_name}</div>
                    <div>+{item.user.tel}</div>
                  </Link>
                </div>

                <div className={'col-span-3'}>
                  {Object.entries(ADMIN_PRIVILEGES).map(([privilege, name]) => (
                    <div key={`admin_privilege_${item.id}_${privilege}`}>
                      <label className={'flex items-center'}>
                        <input
                          type="checkbox"
                          checked={item[privilege]}
                          onChange={() => togglePrivilege(item.id, privilege)}
                          className={'mr-2'}
                        />
                        {name}
                      </label>

                    </div>
                  ))}
                </div>

                <div className={'col-span-3'}>
                  <div>
                    <label className={'flex items-center'}>
                      <input
                        type="checkbox"
                        checked={item.regions.length === 0}
                        onChange={() => toggleAllRegions(item.id)}
                        className={'mr-2'}
                      />
                      Все регионы
                    </label>
                  </div>
                  {item.regions.length === 0 ? (
                    <div className={'text-gray-500'}>Доступ ко всем регионам</div>
                  ) : (
                    <div>
                      {allRegions.map((region) => (
                        <label key={`region_${item.id}_${region.id}`} className={'flex items-center'}>
                          <input
                            type="checkbox"
                            checked={item.regions.some((r) => r.id === region.id)}
                            onChange={() => toggleRegion(item.id, region.id)}
                            className={'mr-2'}
                          />
                          {region.name}
                        </label>
                      ))}
                    </div>
                  )}
                </div>

                <div className={'col-span-2'}>
                  {isAdminModified(item.id) && (
                    <div>
                      <button
                        className={'mt-2 bg-blue-500 text-white px-4 py-2 rounded'}
                        onClick={() => saveAdminChanges(item.id)}
                      >
                        Сохранить
                      </button>
                    </div>
                  )}

                  <div>
                    <button className={'mt-2 bg-red-500 text-white px-4 py-2 rounded'} onClick={() => deleteAdmin(item.id)}>Удалить</button>
                  </div>
                </div>
              </div>
            ))}
          </>
        </>)}
      </div>
    </>);
}
