import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { PHOTOS_BASE_URL } from '../../app.config';
import EditableFragment from '../../components/EditableFragment';
import { TUserProfile, performUserUpdateById, selectManagerList } from '../../reducers/userEditor';
import { useForm, useFormState } from 'react-hook-form';
import { UserEditable } from '../../types/spedddating';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../store';


export const ClientTopCard = (props: {
  profile: TUserProfile,
}) => {
  const telToText = (value: string): string => {
    let m = value.match(/^7(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (m) return '+7 ' + m.slice(1, 5).join(' ');
    return '+' + value;
  };


  const userEditorProfile = props.profile;
  const id = Number(userEditorProfile.data?.id) || 0;

  const dispatch = useDispatch<AppDispatch>();

  const managerList = useSelector(selectManagerList);

  const { register, control, watch: profileWatch, reset, setValue } = useForm<{ d: NonNullable<UserEditable> }>();
  const watch = profileWatch();
  const { dirtyFields } = useFormState({ control });

  useEffect(() => {
    if (userEditorProfile.data) {
      reset({ d: JSON.parse(JSON.stringify(userEditorProfile.data)) });
    }
  }, [reset, userEditorProfile.data]);

  const onSaveProfile = () => {
    dispatch(performUserUpdateById({ id: +id, data: watch.d }));
  };

  if (!userEditorProfile.data) return (<></>);

  return (<>
      <div className={'w-container'}>
        <div className="grid grid-rows-1 lg:grid-cols-12 grid-cols-4 gap-4">
          <div className={'lg:col-span-3 col-span-4 flex flex-col items-center'}>
            {userEditorProfile.data.photos && userEditorProfile.data.photos.length ? (
              <Link className={'w-full'}
                    to={`${PHOTOS_BASE_URL}z${userEditorProfile.data.photos[0].dir}/${userEditorProfile.data.photos[0].big}`}
                    target="_blank">
                <img className={'w-full'}
                     src={`${PHOTOS_BASE_URL}z${userEditorProfile.data.photos[0].dir}/${userEditorProfile.data.photos[0].thumb}`}
                     alt={''}/>
              </Link>
            ) : (
              <div className={'h-36 w-36 bg-gray-400'}>&nbsp;</div>
            )}
          </div>
          <div className={'lg:col-span-7 col-span-4'}>
            <div className={'grid grid-cols-12 gap-4'}>
              <div className={'col-span-8'}>
                <div>
                  <span className={'text-2xl pr-4'}>{userEditorProfile.data.display_name}</span>

                  {userEditorProfile.data.deleted ? (
                    <span className={'bg-black text-white rounded px-2 mr-1'}>УД</span>
                  ) : null}

                  {userEditorProfile.data.is_agency_client ? (
                    <span className={'bg-purple-600 text-white rounded px-2 mr-1'}>БА</span>
                  ) : null}

                  {userEditorProfile.data.hidden_candidate ? (
                    <span className={'bg-gray-600 text-white rounded px-2 mr-1'} title={'БА: Кандидат спрятан'}>БА:СП</span>
                  ) : null}

                  {userEditorProfile.data.is_speeddating_client ? (
                    <span className={'bg-green-600 text-white rounded px-2 mr-1'}>СВ</span>
                  ) : null}

                  {userEditorProfile.data.hidden_call ? (
                    <span className={'bg-gray-600 text-white rounded px-2 mr-1'} title={'Исходящий: Кандидат спрятан'}>ЗВ:СП</span>
                  ) : null}

                </div>
                <div className={'text-xs text-gray-500'}>Цель знакомства: {userEditorProfile.data.display_name}</div>
              </div>
            </div>

            <div className={'pt-2'}>
              <span className={'text-gray-500'}>Контактные данные: </span>
              {telToText(userEditorProfile.data.tel)}
              {userEditorProfile.data.email ? `, ${userEditorProfile.data.email}` : null}
            </div>

            <div className={'pt-2'}>
              <span className={'text-gray-500'}>Менеджер: </span>
              <EditableFragment containerClass={''}
                                register={register('d.agency_client.manager_id')}
                                isDirty={(dirtyFields.d || {} as any).agency_client?.manager_id || false}
                                value={watch.d?.agency_client?.manager_id || ''}
                                input={'select'}
                                options={[['', 'Не задано'], ...managerList.items.map(manager => [manager.id.toString(), manager.admin_privilege?.name || manager.display_name])]}/>
            </div>

            <div className={'pt-2'}>
              <span className={'text-gray-500'}>О клиента: </span>
              <EditableFragment containerClass={''}
                                register={register('d.agency_client.info')}
                                isDirty={(dirtyFields.d || {} as any).agency_client?.info || false}
                                value={watch.d?.agency_client?.info || ''}
                                input={'textarea'}/>
            </div>

          </div>
          <div className={'col-span-2'}>
            <Link className={'btn mb-1 block text-center'} to={`/users/${userEditorProfile.data.id}/view`}>Профиль</Link>
            <Link className={'btn mb-1 block text-center'} to={`/users/${userEditorProfile.data.id}/export`}>Экспорт профиля</Link>
            <Link className={'btn mb-1 block text-center'} to={`/payments/new/${userEditorProfile.data.id}`}>Выставить счет</Link>

            <br/>
            {dirtyFields.d ? (
              <button className={'btn mb-1 block text-center w-full'} onClick={() => onSaveProfile()}>Сохранить</button>) : null}
          </div>
        </div>
      </div>
  </>);
}
