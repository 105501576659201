import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { BASE_URL } from '../app.config';
import { ApiError, Invoice, InvoiceSearchFilter } from '../types/spedddating';
import nest from '../util/nest';

const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};


interface PaymentsReduxInitState {
  invoices: {
    status: 'idle' | 'pending' | 'complete' | 'failed',
    items: Array<Invoice>,
    error: string,
  },
  invoiceCreator: {
    status: 'idle' | 'pending' | 'complete' | 'failed',
    data: null | any,
    error: string,
  }
}

const initialState: PaymentsReduxInitState = {
  invoices: {
    status: 'idle',
    items: [],
    error: '',
  },
  invoiceCreator: {
    status: 'idle',
    data: null,
    error: '',
  },
};

// ------------------------------------------------- CAFES -------------------------------------------------------------

export const fetchInvoices = createAsyncThunk(
  'payment/invoice/list',
  async (filter?: InvoiceSearchFilter) => {

    let filterString = '';
    if (filter) {
      const nested = nest(filter);
      filterString += Object.entries(nested).filter(([key, val]) => val !== '').map(([key, val]) => {
        return 'filter[' + key.split('.').join('][') + ']=' + val;
      }).join('&');
    }

    const result = await axios(`${BASE_URL}/payment/invoice?${filterString}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const createInvoice = createAsyncThunk(
  'payment/invoice',
  async (data: Partial<Invoice>) => {
    const result = await axios(`${BASE_URL}/payment/invoice`, {
      method: 'post',
      withCredentials: true,
      data: data,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const sendInvoice = createAsyncThunk(
  'payment/invoice/id/email',
  async (id: number) => {
    const result = await axios(`${BASE_URL}/payment/invoice/${id}/email`, {
      method: 'post',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);


export const counterSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    createInvoiceReset: ((state) => {
      state.invoiceCreator.status = 'idle';
      state.invoiceCreator.error = '';
    }),
  },
  extraReducers: builder => {
    builder.addCase(fetchInvoices.pending, state => {
      state.invoices.status = 'pending';
      state.invoices.error = '';
    });
    builder.addCase(fetchInvoices.fulfilled, (state, action) => {
      state.invoices.status = 'complete';
      state.invoices.items = [...action.payload.response];
    });
    builder.addCase(fetchInvoices.rejected, (state, action) => {
      // if (state.invoices.status !== "fetching") return;
      state.invoices.status = 'failed';
      state.invoices.error = JSON.stringify(action.error, null, 2);
    });

    builder.addCase(createInvoice.pending, (state, action) => {
      state.invoiceCreator.status = 'pending';
      state.invoiceCreator.error = '';
    });
    builder.addCase(createInvoice.fulfilled, (state, action) => {
      state.invoiceCreator.status = 'complete';
      state.invoiceCreator.error = '';
    });
    builder.addCase(createInvoice.rejected, (state, action) => {
      state.invoiceCreator.status = 'failed';
      state.invoiceCreator.error = JSON.stringify(action.error, null, 2);
    });
  },
});

export const selectInvoices = (state: RootState) => state.payments.invoices;
export const selectInvoiceCreator = (state: RootState) => state.payments.invoiceCreator;
export const { createInvoiceReset } = counterSlice.actions;

export default counterSlice.reducer;
