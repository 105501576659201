import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { BASE_URL } from '../app.config';

import { ApiError, SpeeddatingRequest, SpeeddatingRequestWithUser, SpeeddatingRequestWithUserEditable, User } from '../types/spedddating';


const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};

const _string = (field: any): string | null => {
  if (field === null) return null;
  if (field === '') return null;
  return field;
};
const _stringNotNullable = (field: any): string | null => {
  if (field === null) return '';
  if (field === '') return '';
  return field;
};
const _int = (field: any): number | null => {
  if (field === null) return null;
  if (field === '') return null;
  return +field;
};


interface RequestReduxInitState {
  newRequests: {
    state: 'unknown' | 'fetching' | 'complete' | 'failed',
    items: Array<SpeeddatingRequestWithUser>
  },
  dayRequests: {
    state: 'unknown' | 'fetching' | 'complete' | 'failed',
    items: Array<SpeeddatingRequestWithUser>
  },
  kvRequests: {
    state: 'unknown' | 'fetching' | 'complete' | 'failed',
    items: Array<SpeeddatingRequestWithUser>
  },
  requestEditor: {
    status: 'idle' | 'editing' | 'committing' | 'failed'
    id: number,
    data: SpeeddatingRequestWithUserEditable | null,
    error: string
  },
  visitorInfo: {
    state: 'unknown' | 'fetching' | 'complete' | 'failed',
    data: SpeeddatingRequestWithUser | null,
    error: string,
  },
  event: {
    selected: number,
  },
  visit: {
    selected: number,
  },
}

const initialState: RequestReduxInitState = {
  newRequests: {
    state: 'unknown',
    items: [],
  },
  dayRequests: {
    state: 'unknown',
    items: [],
  },
  kvRequests: {
    state: 'unknown',
    items: [],
  },
  requestEditor: {
    status: 'idle',
    id: 0,
    data: null,
    error: '',
  },
  visitorInfo: {
    state: 'unknown',
    data: null,
    error: '',
  },
  event: {
    selected: 0,
  },
  visit: {
    selected: 0,
  },
};

export const fetchNewSpeeddatingRequests = createAsyncThunk(
  'speeddating/request/new/list',
  async () => {
    const result = await axios(`${BASE_URL}/speeddating/request/new`, {
      method: 'get',
      withCredentials: true,
    });
    return result.data;
  },
);
export const fetchSpeeddatingRequestsForEvent = createAsyncThunk(
  'speeddating/request/day',
  async (id: number | undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const _id = state.speeddating_request.event.selected || id;
    if (!_id) {
      throw new Error('no id');
    }

    const result = await axios(`${BASE_URL}/speeddating/request/for_event/${_id}/day`, {
      method: 'get',
      withCredentials: true,
    });
    return result.data;
  },
);
export const fetchSpeeddatingRequestsForVisit = createAsyncThunk(
  'speeddating/request/evt',
  async (id: number | undefined, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const _id = state.speeddating_request.visit.selected || id;
    if (!_id) {
      throw new Error('no id');
    }

    const result = await axios(`${BASE_URL}/speeddating/request/for_event/${_id}/visit`, {
      method: 'get',
      withCredentials: true,
    });
    return result.data;
  },
);

export const performSpeeddatingRequestEdit = createAsyncThunk(
  'speeddating/request/new/edit',
  async (r: { id: number, data: SpeeddatingRequest, type: 'rec' | 'day' | 'evt', event: number }, thunkAPI) => {
    const isNew = r.id === 0;

    const url = {
      new: {
        rec: `${BASE_URL}/speeddating/request/new`,
        day: `${BASE_URL}/speeddating/request/for_event/${r.event || 0}/day`,
        evt: `${BASE_URL}/speeddating/request/for_event/${r.event || 0}/visit`,
      },
      edit: {
        rec: `${BASE_URL}/speeddating/request/new/${r.id}`,
        day: `${BASE_URL}/speeddating/request/for_event/${r.event || 0}/day/${r.id}`,
        evt: `${BASE_URL}/speeddating/request/for_event/${r.event || 0}/visit/${r.id}`,
      },
    };

    type PartialRecord<K extends keyof any, T> = Partial<Record<K, T>>

    const valid = (key: keyof SpeeddatingRequest) => {
      const validators: PartialRecord<keyof SpeeddatingRequest, (any)> = {
        name: _stringNotNullable,
        tel: _stringNotNullable,
        email: _string,
        gender: _string,
        age: _string,
        speeddating_event_id: _int,
        price: _int,
        paid_cash: _int,
        status_reg: _string,
        status_day: _string,
        status_evt: _string,
        code: _string,
        comment_km: _stringNotNullable,
        comment_kv: _stringNotNullable,
      };

      if (key in validators)
        return validators[key](r.data[key]);
      return r.data[key];
    };

    const props = {
      new: {
        rec: ['name', 'age', 'tel', 'email', 'gender', 'speeddating_event_id', 'price', 'status_reg', 'comment_km', 'comment_kv'],
        day: ['name', 'age', 'tel', 'email', 'gender', 'speeddating_event_id', 'price', 'status_day', 'comment_km', 'comment_kv'],
        evt: ['name', 'age', 'tel', 'email', 'gender', 'speeddating_event_id', 'paid_cash', 'status_evt', 'code', 'comment_km'],
      },
      edit: {
        rec: ['gender', 'speeddating_event_id', 'price', 'status_reg', 'comment_km', 'comment_kv'],
        day: ['gender', 'speeddating_event_id', 'price', 'status_day', 'comment_km', 'comment_kv'],
        evt: ['gender', 'paid_cash', 'status_evt', 'code', 'comment_km'],
      },
    };

    const data: any = {};
    (props[isNew ? 'new' : 'edit'][r.type] as Array<keyof SpeeddatingRequest>).forEach(key => {
      data[key] = valid(key);
    });

    const result = await axios(url[isNew ? 'new' : 'edit'][r.type], {
      method: isNew ? 'post' : 'put',
      withCredentials: true,
      data,
    }).catch(apiErrorHandler);

    if (r.type === 'evt') thunkAPI.dispatch(fetchSpeeddatingRequestsForVisit(r.event));
    if (r.type === 'day') thunkAPI.dispatch(fetchSpeeddatingRequestsForEvent(r.event));
    if (r.type === 'rec') thunkAPI.dispatch(fetchNewSpeeddatingRequests());

    return result.data;
  },
);


export const fetchSpeeddatingVisitorInfo = createAsyncThunk(
  'speeddating/request/get',
  async (r: { id: number }) => {
    const result = await axios(`${BASE_URL}/speeddating/request/${r.id}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const counterSlice = createSlice({
  name: 'speeddating_request',
  initialState,
  reducers: {
    requestEdit: (state, action: PayloadAction<{ id: number, data: SpeeddatingRequestWithUser }>) => {
      state.requestEditor = {
        status: 'editing',
        id: action.payload.id,
        data: {
          id: '' + action.payload.data.id,
          speeddating_event_id: '' + action.payload.data.speeddating_event_id,
          user_id: '' + action.payload.data.user_id,
          name: action.payload.data.name,
          tel: action.payload.data.tel,
          email: action.payload.data.email,
          age: action.payload.data.age ? '' + action.payload.data.age : '',
          gender: action.payload.data.gender,
          price: '' + action.payload.data.price,
          paid_online: '' + action.payload.data.paid_online,
          paid_cash: '' + action.payload.data.paid_cash,
          status: action.payload.data.status,
          status_reg: action.payload.data.status_reg,
          status_day: action.payload.data.status_day,
          status_evt: action.payload.data.status_evt,
          code: action.payload.data.code,
          comment_km: action.payload.data.comment_km,
          comment_kv: action.payload.data.comment_kv,
          user: action.payload.data.user ? { ...action.payload.data.user } : undefined,
          manager_id: '' + action.payload.data.manager_id,
        },
        error: '',
      };
    },
    requestReset: (state) => {
      state.requestEditor = {
        status: 'idle',
        id: 0,
        data: null,
        error: '',
      };
    },

    setUser: (state, action: PayloadAction<User>) => {
      const d: typeof state.requestEditor = {
        status: 'editing',
        id: 0,
        error: '',
        data: null,
      };

      if (state.requestEditor.data?.user) {
        d.data = { ...state.requestEditor.data };
        d.data.user = { ...action.payload };
      } else {
        d.data = {
          id: '0',
          speeddating_event_id: '0',
          user_id: '' + action.payload.id,
          name: action.payload.display_name,
          tel: action.payload.tel,
          email: action.payload.email || '',
          age: '' + action.payload.age || '0', // TODO: count age from birth
          gender: action.payload.gender,
          price: '0',
          paid_online: '0',
          paid_cash: '0',
          status: 'new',
          status_reg: 'new',
          status_day: 'new',
          status_evt: 'waiting',
          code: '',
          comment_km: '',
          comment_kv: '',
          manager_id: '0',
          user: { ...action.payload },
        };
      }

      state.requestEditor = d;
    },

    setManagerEventState: (state, action: PayloadAction<RequestReduxInitState['event']>) => {
      state.event = { ...action.payload };
    },
    setManagerVisitState: (state, action: PayloadAction<RequestReduxInitState['visit']>) => {
      state.visit = { ...action.payload };
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchNewSpeeddatingRequests.pending, (state, action) => {
      state.newRequests.state = 'fetching';
    });
    builder.addCase(fetchNewSpeeddatingRequests.fulfilled, (state, action) => {
      state.newRequests.state = 'complete';
      state.newRequests.items = action.payload.response;
    });
    builder.addCase(fetchNewSpeeddatingRequests.rejected, (state, action) => {
      state.newRequests.state = 'failed';
    });


    builder.addCase(fetchSpeeddatingRequestsForEvent.pending, (state, action) => {
      state.dayRequests.state = 'fetching';
    });
    builder.addCase(fetchSpeeddatingRequestsForEvent.fulfilled, (state, action) => {
      state.dayRequests.state = 'complete';
      state.dayRequests.items = action.payload.response;
    });
    builder.addCase(fetchSpeeddatingRequestsForEvent.rejected, (state, action) => {
      state.dayRequests.state = 'failed';
    });

    builder.addCase(fetchSpeeddatingRequestsForVisit.pending, (state, action) => {
      state.kvRequests.state = 'fetching';
    });
    builder.addCase(fetchSpeeddatingRequestsForVisit.fulfilled, (state, action) => {
      state.kvRequests.state = 'complete';
      state.kvRequests.items = action.payload.response;
    });
    builder.addCase(fetchSpeeddatingRequestsForVisit.rejected, (state, action) => {
      state.kvRequests.state = 'failed';
    });


    builder.addCase(performSpeeddatingRequestEdit.pending, (state) => {
      state.requestEditor.status = 'committing';
    });
    builder.addCase(performSpeeddatingRequestEdit.fulfilled, (state) => {
      state.requestEditor = {
        status: 'idle',
        id: 0,
        data: null,
        error: '',
      };
    });
    builder.addCase(performSpeeddatingRequestEdit.rejected, (state, action) => {
      state.requestEditor.status = 'failed';
      state.requestEditor.error = JSON.stringify(action.error, null, 2);
    });

    builder.addCase(fetchSpeeddatingVisitorInfo.pending, state => {
      state.visitorInfo.state = 'fetching';
    });
    builder.addCase(fetchSpeeddatingVisitorInfo.fulfilled, (state, action) => {
      state.visitorInfo.state = 'complete';
      state.visitorInfo.data = action.payload.response as SpeeddatingRequestWithUser;
      state.visitorInfo.data.user?.speeddating_requests?.sort((sr1, sr2) => sr2.id - sr1.id);
    });
    builder.addCase(fetchSpeeddatingVisitorInfo.rejected, (state, action) => {
      state.visitorInfo.state = 'failed';
      state.visitorInfo.error = JSON.stringify(action.error, null, 2);
    });
  },
});

export const selectSpeeddatingRequestsState = (state: RootState) => state.speeddating_request;
export const selectSpeeddatingNewRequests = (state: RootState) => state.speeddating_request.newRequests;
export const selectSpeeddatingDayRequests = (state: RootState) => state.speeddating_request.dayRequests;
export const selectSpeeddatingKVRequests = (state: RootState) => state.speeddating_request.kvRequests;
export const selectSpeeddatingVisitorInfo = (state: RootState) => state.speeddating_request.visitorInfo;

export const selectSpeeddatingRequestEditor = (state: RootState) => state.speeddating_request.requestEditor;

export const selectManagerEventState = (state: RootState) => state.speeddating_request.event;
export const selectManagerVisitState = (state: RootState) => state.speeddating_request.visit;

export const { requestEdit, requestReset, setUser, setManagerEventState, setManagerVisitState } = counterSlice.actions;

export default counterSlice.reducer;
