import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { BASE_URL } from '../app.config';
import {
  AgencyClientMatchEditable,
  AgencyClientStatusEditable,
  AgencyClientTaskEditable,
  ApiError,
  UserListFilter,
  User,
  UserEditable,
  PartialDeep,
} from '../types/spedddating';
import nest from '../util/nest';

const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};

const _string = (field: any): string | null => {
  if (field === null) return null;
  if (field === '') return null;
  return field;
};
const _int = (field: any, not: any[] = []): number | null => {
  if (field === null) return null;
  if (field === '') return null;
  if (not.reduce((a, c) => a || (c === field), false)) return null;
  return +field;
};
const _date = (field: any): string | null => {
  const date = _string(field);
  if (!date) return date;

  // DD.MM.YYYY format
  let match = date.match(/^(\d{2}).(\d{2}).(\d{4})$/);
  if (match) {
    const [_match, d, m, y] = match;
    return `${y}-${m}-${d}`;
  }

  return date;
};

export type TUserProfile = {
  status: 'idle' | 'fetching' | 'complete' | 'failed',
  data: UserEditable | null,
  error: string,
  microFormsEditing: boolean
}

export enum ERequestStatus {
  IDLE,
  FETCHING,
  COMPLETE,
  FAILED,
}

interface UserEditorReduxInitState {
  userListQuery: {
    filter: UserListFilter,
    offset: number,
    limit: number,
    order: string,
  },

  userList: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    items: Array<User>,
    error: string,
    total: number,
  },

  userCreateState: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    user: User | null,
    error: string,
  },

  candidatesList: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    items: Array<User>,
    error: string,
    total: number,
    loading: Record<number, 'loading' | 'done'>,
  },

  userProfile: TUserProfile,

  userEditorState: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    data: any,
    error: string,
  },

  userStateIntl: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    data: any,
    error: string,
  },

  userEditorStateIntl: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    data: any,
    error: string,
  },

  userExpoState: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    error: string,
  },

  userPasswordResetState: {
    status: ERequestStatus;
    error: string;
    data: null | { token: string, login: string };
  }

  managerList: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    items: Array<User>,
    error: string,
    by_id: Record<number, User>
  }
}

const initialState: UserEditorReduxInitState = {
  userListQuery: {
    filter: {},
    offset: 0,
    limit: 25,
    order: '',
  },
  userList: {
    status: 'idle',
    items: [],
    error: '',
    total: 0,
  },
  userCreateState: {
    status: 'idle',
    user: null,
    error: '',
  },
  candidatesList: {
    status: 'idle',
    items: [],
    error: '',
    total: 0,
    loading: {},
  },
  userProfile: {
    status: 'idle',
    error: '',
    data: null,
    microFormsEditing: false,
  },
  userEditorState: {
    status: 'idle',
    error: '',
    data: null,
  },
  userEditorStateIntl: {
    status: 'idle',
    error: '',
    data: null,
  },
  userStateIntl: {
    status: 'idle',
    error: '',
    data: null,
  },
  managerList: {
    status: 'idle',
    items: [],
    error: '',
    by_id: {},
  },
  userExpoState: {
    status: 'idle',
    error: '',
  },
  userPasswordResetState: {
    status: ERequestStatus.IDLE,
    error: '',
    data: null,
  },
};

// noinspection DuplicatedCode
export const performUserListQuery = createAsyncThunk(
  'user/listQuery',
  async (query: {
    includeCallTable?: number,
    forAgencyClient?: number,
    includeHiddenCandidates?: number,
    filter?: UserListFilter,
  } | undefined, { getState }) => {

    const { userEditor } = getState() as RootState;
    const filter = (query && query.filter) ? query.filter : userEditor.userListQuery.filter;
    const order = userEditor.userListQuery.order;

    let filterString = `?offset=${userEditor.userListQuery.offset}&limit=${userEditor.userListQuery.limit}&`;

    if (query && query.includeCallTable) filterString += 'call_details=1&';
    if (query && query.forAgencyClient) filterString += `for_agency_client=${query.forAgencyClient}&`;
    if (query && query.includeHiddenCandidates !== undefined) filterString += `include_hidden_candidates=${query.includeHiddenCandidates}&`;
    if (order) filterString += `order_by=${order}&`;

    if (filter) {
      const nested = nest(filter);
      filterString += Object.entries(nested).map(([key, val]) => {
        return 'filter[' + key.split('.').join('][') + ']=' + val;
      }).join('&');
    }

    const result = await axios(`${BASE_URL}/user/search${filterString}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

// noinspection DuplicatedCode
export const performUserCreate = createAsyncThunk(
  'user/create',
  async (user: User) => {

    const data: PartialDeep<User> = {
      tel: user.tel,
      display_name: _string(user.display_name) || '',
      email: _string(user.email),
      gender: user.gender,
      birth: _string(user.birth),
      age: _int(user.age),
    };

    const result = await axios(`${BASE_URL}/user`, {
      method: 'post',
      withCredentials: true,
      data: data,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

// noinspection DuplicatedCode
export const performAgencyCandidatesSearch = createAsyncThunk(
  'candidates/search',
  async (r: { for_user?: number }, { getState }) => {

    const { userEditor } = getState() as RootState;
    const filter = userEditor.userListQuery.filter;

    let filterString = `?offset=${userEditor.userListQuery.offset}&limit=${userEditor.userListQuery.limit}&`;
    if (filter) {
      filterString += Object.entries(filter)
        .map(([key, val]) => {
          if (val === '') return null;
          return `filter[${key}]=${val}`;
        })
        .filter(item => item !== null)
        .join('&');
    }

    const result = await axios(`${BASE_URL}/agency/candidates${filterString}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

// noinspection DuplicatedCode
export const performUserGetById = createAsyncThunk(
  'user/by_id/get',
  async (r: { id: number }) => {
    const result = await axios(`${BASE_URL}/user/by_id/${r.id}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    // Transform user to editable
    result.data.response.regions = result.data.response.regions.map(({ id }) => String(id));

    return result.data;
  },
);

export const performUserAddPhoto = createAsyncThunk(
  'user/addPhoto',
  async (r: { userId: number, photo: any }, thunkAPI) => {
    const form = new FormData();
    form.append('user_id', ('' + r.userId));
    form.append('file', r.photo);

    const result = await axios.post(`${BASE_URL}/user/photo`, form, {
      headers: { 'content-type': 'multipart/form-data' },
      withCredentials: true,
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserGetById({ id: r.userId }));

    return result.data;
  },
);

export const performUserDeletePhoto = createAsyncThunk(
  'user/deletePhoto',
  async (r: { userId?: number, photoId: number }, thunkAPI) => {

    const result = await axios(`${BASE_URL}/user/photo/${r.photoId}`, {
      method: 'delete',
      withCredentials: true,
    }).catch(apiErrorHandler);

    if (r.userId) thunkAPI.dispatch(performUserGetById({ id: r.userId }));

    return result.data;
  },
);

export const performUserSetMainPhoto = createAsyncThunk(
  'user/photo/main',
  async (r: { userId?: number, photoId: number }, thunkAPI) => {

    const result = await axios(`${BASE_URL}/user/photo/${r.photoId}/main`, {
      method: 'post',
      withCredentials: true,
    }).catch(apiErrorHandler);

    if (r.userId) thunkAPI.dispatch(performUserGetById({ id: r.userId }));

    return result.data;
  },
);

export const performUserUpdateByIdNew = createAsyncThunk(
  'user/by_id/set2',
  async (r: { id: number, data: { pass?: string } }, thunkAPI) => {
    const body: Record<string, any> = {};

    if (typeof r.data.pass === 'string') body.pass = r.data.pass;

    if (Object.keys(body).length === 0) {
      return;
    }

    const result = await axios(`${BASE_URL}/user/by_id/${r.id}`, {
      method: 'put',
      withCredentials: true,
      data: body,
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserGetById({ id: r.id }));

    return result.data;
  },
);

export const performUserGetByIdInternational = createAsyncThunk(
  'user/by_id/i18n/get',
  async (r: { id: number, lang: string }, thunkAPI) => {
    const result = await axios(`${BASE_URL}/user/by_id/${r.id}/international/${r.lang}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const performUserUpdateByIdInternational = createAsyncThunk(
  'user/by_id/i18n/set',
  async (r: { id: number, lang: string, data: any }, thunkAPI) => {
    const body: Record<string, any> = {};

    if (typeof r.data.display_name === 'string') body.display_name = _string(r.data.display_name);
    if (typeof r.data.about === 'string') body.about = _string(r.data.about);

    const result = await axios(`${BASE_URL}/user/by_id/${r.id}/international/${r.lang}`, {
      method: 'put',
      withCredentials: true,
      data: body,
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserGetByIdInternational({ id: r.id, lang: r.lang }));

    return result.data;
  },
);

export const performUserPasswordResetLink = createAsyncThunk(
  'user/by_id/reset',
  async (r: { id: number }) => {
    const result = await axios(`${BASE_URL}/user/by_id/${r.id}/reset`, {
      method: 'post',
      withCredentials: true,
      data: {},
    }).catch(apiErrorHandler);

    return result.data;
  },
);

// noinspection DuplicatedCode
export const performUserUpdateById = createAsyncThunk(
  'user/by_id/set',
  async (r: { id: number, data: UserEditable }, thunkAPI) => {

    const data: PartialDeep<User> = {
      tel: r.data.tel,
      display_name: _string(r.data.display_name) || '',
      email: _string(r.data.email),
      gender: r.data.gender,
      birth: _date(r.data.birth),
      age: _int(r.data.age, [0]),
      birth_year: _int(r.data.birth_year, [0]),
      deleted: +r.data.deleted,
      hidden_candidate: +r.data.hidden_candidate,
      hidden_call: +r.data.hidden_call,
      speeddating_manager_id: _int(r.data.speeddating_manager_id),
    };
    if (r.data.regions) {
      data.regions = r.data.regions.map((region) => ({ id: Number(region), name: '' }));
    }
    if (r.data.user_profile) {
      data.user_profile = {
        height: _int(r.data.user_profile.height),
        weight: _int(r.data.user_profile.weight),
        hair_color: _string(r.data.user_profile.hair_color),
        eyes_color: _string(r.data.user_profile.eyes_color),
        body_type: _string(r.data.user_profile.body_type),
        birth_location: _string(r.data.user_profile.birth_location),
        city: _string(r.data.user_profile.city),
        district: _string(r.data.user_profile.district),
        tattoo: _int(r.data.user_profile.tattoo),
        piercing: _int(r.data.user_profile.piercing),
        nationality: _string(r.data.user_profile.nationality),
        religion: _string(r.data.user_profile.religion),
        martial_status: _string(r.data.user_profile.martial_status),
        children: _int(r.data.user_profile.children),
        children_data: null,
        wait_children: _int(r.data.user_profile.wait_children),
        housing_type: _string(r.data.user_profile.housing_type),
        housing_ownership: _int(r.data.user_profile.housing_ownership),
        auto: _int(r.data.user_profile.auto),
        driver_license: _int(r.data.user_profile.driver_license),
        auto_model: _string(r.data.user_profile.auto_model),
        cohabitant: _string(r.data.user_profile.cohabitant),
        education: _string(r.data.user_profile.education),
        field_of_activity: _string(r.data.user_profile.field_of_activity),
        company_position: _string(r.data.user_profile.company_position),
        own_business: _int(r.data.user_profile.own_business),
        own_business_area: _string(r.data.user_profile.own_business_area),
        income: _int(r.data.user_profile.income),
        has_pets: _int(r.data.user_profile.has_pets),
        pets: null,
        hobby: _string(r.data.user_profile.hobby),
        smoking: _int(r.data.user_profile.smoking),
        foreign_languages: _int(r.data.user_profile.foreign_languages),
        foreign_languages_what: null,
        vegan: _int(r.data.user_profile.vegan),
        attitude_to_art: _int(r.data.user_profile.attitude_to_art),
        attitude_to_travelling: _int(r.data.user_profile.attitude_to_travelling),
        attitude_to_pets: _int(r.data.user_profile.attitude_to_pets),
        attitude_to_sport: _int(r.data.user_profile.attitude_to_sport),
        attitude_to_smoking: _int(r.data.user_profile.attitude_to_smoking),
        attitude_to_alcohol: _int(r.data.user_profile.attitude_to_alcohol),
        attitude_to_drugs: _int(r.data.user_profile.attitude_to_drugs),
        searching_for: _string(r.data.user_profile.searching_for),
        about: _string(r.data.user_profile.about),
      };
    }
    if (r.data.agency_client) {
      data.agency_client = {
        tel: _string(r.data.agency_client.tel),
        email: _string(r.data.agency_client.email),
        manager_id: _int(r.data.agency_client.manager_id),
        info: _string(r.data.agency_client.info),
      };
    }

    const result = await axios(`${BASE_URL}/user/by_id/${r.id}`, {
      method: 'put',
      withCredentials: true,
      data: data,
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserGetById({ id: r.id }));

    return result.data;
  },
);

// noinspection DuplicatedCode
export const performExpoUserSet = createAsyncThunk(
  'expo/set',
  async (r: { data: { user_id: number, visible: boolean } }, thunkAPI) => {
    const result = await axios(`${BASE_URL}/expo/set/`, {
      method: 'post',
      withCredentials: true,
      data: r.data,
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserGetById({ id: r.data.user_id }));

    return result.data;
  },
);


// noinspection DuplicatedCode
export const performAgencyMatchStatusUpdate = createAsyncThunk(
  'agency/match/status',
  async (r: { id: number, data: Partial<AgencyClientMatchEditable> }, thunkAPI) => {
    const result = await axios(`${BASE_URL}/agency/client/${r.id}/match`, {
      method: 'post',
      withCredentials: true,
      data: r.data,
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserListQuery({ forAgencyClient: r.id }));
    thunkAPI.dispatch(performUserGetById({ id: r.id }));

    return result.data;
  },
);

// noinspection DuplicatedCode
export const performAgencyMatchDelete = createAsyncThunk(
  'agency/match/status',
  async (r: { id: number, match_id: number }, thunkAPI) => {
    const result = await axios(`${BASE_URL}/agency/client/${r.id}/match/${r.match_id}`, {
      method: 'delete',
      withCredentials: true,
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserListQuery({ forAgencyClient: r.id }));
    thunkAPI.dispatch(performUserGetById({ id: r.id }));

    return result.data;
  },
);

// noinspection DuplicatedCode
export const performAgencyClientStatusUpdate = createAsyncThunk(
  'agency/client/status',
  async (r: { id: number, data: AgencyClientStatusEditable }, thunkAPI) => {
    const result = await axios(`${BASE_URL}/agency/client/${r.id}/status`, {
      method: 'post',
      withCredentials: true,
      data: r.data,
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserGetById({ id: r.id }));

    return result.data;
  },
);

// noinspection DuplicatedCode
export const performAgencyClientTaskUpdate = createAsyncThunk(
  'agency/client/task',
  async (r: { id: number, data: AgencyClientTaskEditable }, thunkAPI) => {
    const result = await axios(`${BASE_URL}/agency/client/${r.id}/task`, {
      method: 'post',
      withCredentials: true,
      data: {
        ...r.data,
        date: _date(r.data.date),
        ends: _date(r.data.ends),
      },
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(performUserGetById({ id: r.id }));

    return result.data;
  },
);

// noinspection DuplicatedCode
export const fetchManagerList = createAsyncThunk(
  'user/manager/list',
  async () => {
    const result = await axios(`${BASE_URL}/user/manager`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const userEditorSlice = createSlice({
  name: 'userEditor',
  initialState,
  reducers: {
    userListQuerySet: ((state, action: PayloadAction<Partial<typeof initialState.userListQuery>>) => {
      if (typeof action.payload.offset !== 'undefined') state.userListQuery.offset = action.payload.offset;
      if (typeof action.payload.limit !== 'undefined') state.userListQuery.limit = action.payload.limit;
      if (typeof action.payload.filter !== 'undefined') state.userListQuery.filter = { ...action.payload.filter };
      if (typeof action.payload.order !== 'undefined') state.userListQuery.order = action.payload.order;
    }),
    userProfileSet: ((state, action: PayloadAction<Partial<typeof initialState.userProfile>>) => {

    }),

    userListReset: ((state) => {
      state.userList.items = [];
    }),

    userPasswordResetLinkReset: ((state) => {
      state.userPasswordResetState.status = ERequestStatus.IDLE;
      state.userPasswordResetState.error = '';
      state.userPasswordResetState.data = null;
    }),
  },
  extraReducers: builder => {
    builder.addCase(performUserListQuery.pending, state => {
      state.userList.error = '';
      state.userList.status = 'fetching';
    });
    builder.addCase(performUserListQuery.fulfilled, (state, action) => {
      if (state.userList.status !== 'fetching') return;
      state.userList.status = 'complete';
      state.userList.items = action.payload.response;
      state.userList.total = +action.payload.total;
    });
    builder.addCase(performUserListQuery.rejected, (state, action) => {
      if (state.userList.status !== 'fetching') return;
      state.userList.status = 'failed';
      state.userList.error = JSON.stringify(action.error, null, 2);
    });

    // -----

    builder.addCase(performUserGetById.pending, state => {
      state.userProfile.status = 'fetching';
      state.userProfile.error = '';
      state.userProfile.data = null;
    });
    builder.addCase(performUserGetById.fulfilled, (state, action) => {
      if (state.userProfile.status !== 'fetching') return;
      state.userProfile.status = 'complete';
      // state.userProfile.data = action.payload.response;

      const up: User = action.payload.response;
      const d: any = {};
      Object.entries(up).forEach(([key, val]) => {
        d[key] = val === null ? '' : val;
      });
      if (up.user_profile) Object.entries(up.user_profile).forEach(([key, val]) => {
        if (key === 'children_data' || key === 'foreign_languages_what') {
          if (val === null) d.user_profile[key] = [];
        } else if (val === null) d.user_profile[key] = '';
        else if (typeof val === 'number') d.user_profile[key] = `${val}`;
        else if (typeof val === 'string') d.user_profile[key] = `${val}`;
      });
      state.userProfile.data = d as UserEditable;
    });
    builder.addCase(performUserGetById.rejected, (state, action) => {
      if (state.userProfile.status !== 'fetching') return;
      state.userProfile.status = 'failed';
      state.userProfile.error = JSON.stringify(action.error, null, 2);
    });

    // -----

    // noinspection DuplicatedCode
    builder.addCase(performUserCreate.pending, state => {
      state.userCreateState.status = 'fetching';
      state.userCreateState.error = '';
      state.userCreateState.user = null;
    });
    builder.addCase(performUserCreate.fulfilled, (state, action) => {
      if (state.userCreateState.status !== 'fetching') return;
      state.userCreateState.status = 'complete';
      state.userCreateState.user = action.payload.response;
    });
    builder.addCase(performUserCreate.rejected, (state, action) => {
      if (state.userCreateState.status !== 'fetching') return;
      state.userCreateState.status = 'failed';
      state.userCreateState.error = JSON.stringify(action.error, null, 2);
    });

    // -----

    builder.addCase(performUserUpdateById.pending, state => {
      state.userEditorState.error = '';
      state.userEditorState.data = null;
    });
    builder.addCase(performUserUpdateById.fulfilled, (state, action) => {
      state.userEditorState.status = 'complete';
      state.userEditorState.data = action.payload.response;
    });
    builder.addCase(performUserUpdateById.rejected, (state, action) => {
      state.userEditorState.status = 'failed';
      state.userEditorState.error = JSON.stringify(action.error, null, 2);
    });

    // -----

    // performUserUpdateByIdInternational

    builder.addCase(performUserUpdateByIdInternational.pending, state => {
      state.userEditorStateIntl.error = '';
      state.userEditorStateIntl.data = null;
    });
    builder.addCase(performUserUpdateByIdInternational.fulfilled, (state, action) => {
      state.userEditorStateIntl.status = 'complete';
      state.userEditorStateIntl.data = action.payload.response;
    });
    builder.addCase(performUserUpdateByIdInternational.rejected, (state, action) => {
      state.userEditorStateIntl.status = 'failed';
      state.userEditorStateIntl.error = JSON.stringify(action.error, null, 2);
    });


    // performUserUpdateByIdInternational

    builder.addCase(performUserGetByIdInternational.pending, state => {
      state.userStateIntl.error = '';
      state.userStateIntl.data = null;
    });
    builder.addCase(performUserGetByIdInternational.fulfilled, (state, action) => {
      state.userStateIntl.status = 'complete';
      state.userStateIntl.data = action.payload.response;
    });
    builder.addCase(performUserGetByIdInternational.rejected, (state, action) => {
      state.userStateIntl.status = 'failed';
      state.userStateIntl.error = JSON.stringify(action.error, null, 2);
    });

    // -----

    builder.addCase(performUserUpdateByIdNew.pending, state => {
      state.userEditorState.error = '';
      state.userEditorState.data = null;
    });
    builder.addCase(performUserUpdateByIdNew.fulfilled, (state, action) => {
      state.userEditorState.status = 'complete';
      state.userEditorState.data = action.payload.response;
    });
    builder.addCase(performUserUpdateByIdNew.rejected, (state, action) => {
      state.userEditorState.status = 'failed';
      state.userEditorState.error = JSON.stringify(action.error, null, 2);
    });


    // -----
    builder.addCase(performAgencyMatchStatusUpdate.pending, (state, action) => {
      if (action.meta.arg.data?.match_id)
        state.candidatesList.loading = {
          ...state.candidatesList.loading,
          [action.meta.arg.data.match_id]: 'loading',
        };
    });
    builder.addCase(performAgencyMatchStatusUpdate.fulfilled, (state, action) => {
      state.userProfile.microFormsEditing = false;
      if (action.meta.arg.data?.match_id)
        state.candidatesList.loading = {
          ...state.candidatesList.loading,
          [action.meta.arg.data.match_id]: 'done',
        };
    });
    builder.addCase(performAgencyClientStatusUpdate.fulfilled, (state, action) => {
      state.userProfile.microFormsEditing = false;
    });
    builder.addCase(performAgencyClientTaskUpdate.fulfilled, (state, action) => {
      state.userProfile.microFormsEditing = false;
    });

    // -----
    builder.addCase(performAgencyCandidatesSearch.pending, state => {
      state.candidatesList.status = 'fetching';
    });
    builder.addCase(performAgencyCandidatesSearch.fulfilled, (state, action) => {
      if (state.candidatesList.status !== 'fetching') return;
      state.candidatesList.status = 'complete';
      state.candidatesList.items = action.payload.response;
      state.candidatesList.total = +action.payload.total;
    });
    builder.addCase(performAgencyCandidatesSearch.rejected, (state, action) => {
      if (state.candidatesList.status !== 'fetching') return;
      state.candidatesList.status = 'failed';
      state.candidatesList.error = JSON.stringify(action.error, null, 2);
    });

    // fetchManagerList
    builder.addCase(fetchManagerList.pending, (state) => {
      state.managerList.status = 'fetching';
      state.managerList.error = '';
      state.managerList.items = [];
    });
    builder.addCase(fetchManagerList.fulfilled, (state, action) => {
      state.managerList.status = 'complete';
      state.managerList.error = '';
      state.managerList.items = action.payload.response;
      state.managerList.by_id = (action.payload.response as User[]).reduce((a, c) => ({ ...a, [c.id]: c }), {});
    });
    builder.addCase(fetchManagerList.rejected, (state, action) => {
      state.managerList.status = 'failed';
      state.managerList.error = JSON.stringify(action.error, null, 2);
      state.managerList.items = [];
    });

    // performExpoUserSet
    builder.addCase(performExpoUserSet.pending, (state) => {
      state.userExpoState.status = 'fetching';
      state.userExpoState.error = '';
    });
    builder.addCase(performExpoUserSet.fulfilled, (state) => {
      state.userExpoState.status = 'complete';
      state.userExpoState.error = '';
    });
    builder.addCase(performExpoUserSet.rejected, (state, action) => {
      state.userExpoState.status = 'failed';
      state.userExpoState.error = JSON.stringify(action.error, null, 2);
    });

    // performUserPasswordResetLink
    builder.addCase(performUserPasswordResetLink.pending, (state) => {
      state.userPasswordResetState.status = ERequestStatus.FETCHING;
      state.userPasswordResetState.error = '';
      state.userPasswordResetState.data = null;
    });
    builder.addCase(performUserPasswordResetLink.fulfilled, (state, action) => {
      state.userPasswordResetState.status = ERequestStatus.COMPLETE;
      state.userPasswordResetState.error = '';
      state.userPasswordResetState.data = action.payload.response;
    });
    builder.addCase(performUserPasswordResetLink.rejected, (state, action) => {
      state.userPasswordResetState.status = ERequestStatus.FAILED;
      state.userPasswordResetState.error = JSON.stringify(action.error, null, 2);
      state.userPasswordResetState.data = null;
    });
  },
});

export const selectUserEditorList = (state: RootState) => state.userEditor.userList;
export const selectUserEditorListQuery = (state: RootState) => state.userEditor.userListQuery;
export const selectUserEditorProfile = (state: RootState) => state.userEditor.userProfile;
export const selectUserEditorState = (state: RootState) => state.userEditor.userEditorState;
export const selectUserCreateState = (state: RootState) => state.userEditor.userCreateState;
export const selectCandidateList = (state: RootState) => state.userEditor.candidatesList;
export const selectManagerList = (state: RootState) => state.userEditor.managerList;
export const selectUserPasswordResetState = (state: RootState) => state.userEditor.userPasswordResetState;
export const selectUserStateIntl = (state: RootState) => state.userEditor.userStateIntl;
export const selectUserEditorStateIntl = (state: RootState) => state.userEditor.userEditorStateIntl;
export const { userListQuerySet, userProfileSet, userListReset, userPasswordResetLinkReset } = userEditorSlice.actions;

export default userEditorSlice.reducer;
