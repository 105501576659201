import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { BASE_URL } from '../app.config';
import { ApiError } from '../types/spedddating';


const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};


interface ManagerCallStatsReduxInitState {
  stats: {
    state: 'idle' | 'fetching' | 'complete' | 'failed',
    items: Array<any>,
    error: string
  },
  logs: {
    state: 'idle' | 'fetching' | 'complete' | 'failed',
    id: number,
    items: Array<any>,
    error: string
  },
}

const initialState: ManagerCallStatsReduxInitState = {
  stats: {
    state: 'idle',
    items: [],
    error: '',
  },
  logs: {
    state: 'idle',
    id: 0,
    items: [],
    error: '',
  },
};

export const performFetchManagerCallStats = createAsyncThunk(
  'speeddating/call/stats/get',
  async () => {
    const result = await axios(`${BASE_URL}/speeddating/call/stats`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const performFetchManagerCallLogs = createAsyncThunk(
  'speeddating/call/logs/manager_id/get',
  async (manager_id: number) => {
    const result = await axios(`${BASE_URL}/speeddating/call/logs/${manager_id}`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const managerCallStatsSlice = createSlice({
  name: 'managerCallStats',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(performFetchManagerCallStats.pending, state => {
      // console.log(Date.now(), "performUserSearch: pending");
      state.stats.state = 'fetching';
    });
    builder.addCase(performFetchManagerCallStats.fulfilled, (state, action) => {
      if (state.stats.state !== 'fetching') return;
      state.stats.state = 'complete';
      state.stats.items = action.payload.response;
    });
    builder.addCase(performFetchManagerCallStats.rejected, (state, action) => {
      if (state.stats.state !== 'fetching') return;
      state.stats.state = 'failed';
      state.stats.error = JSON.stringify(action.error, null, 2);
    });

    builder.addCase(performFetchManagerCallLogs.pending, (state, action) => {
      // console.log(Date.now(), "performUserSearch: pending");
      state.logs.state = 'fetching';
      state.logs.id = action.meta.arg;
    });
    builder.addCase(performFetchManagerCallLogs.fulfilled, (state, action) => {
      state.logs.state = 'complete';
      state.logs.items = action.payload.response;
    });
    builder.addCase(performFetchManagerCallLogs.rejected, (state, action) => {
      state.logs.state = 'failed';
      state.logs.error = JSON.stringify(action.error, null, 2);
    });

  },
});

export const selectManagerCallStats = (state: RootState) => state.managerCallStats.stats;
export const selectManagerCallLogs = (state: RootState) => state.managerCallStats.logs;

// export const {userEdit, userEditReset, resetUserSearchResults} = managerCallStatsSlice.actions;

export default managerCallStatsSlice.reducer;
