import React from 'react';
import { SpeeddatingRequest } from '../../types/spedddating';
import moment from 'moment';
import cx from 'classnames';
import 'moment/locale/ru';
import { selectSpeeddatingVisitorInfo } from '../../reducers/spedddatingRequests';
import { useSelector } from 'react-redux';
import Error from '../../components/error';

export default function EventHistoryCard () {
  const visitorInfo = useSelector(selectSpeeddatingVisitorInfo);
  const visitorData = visitorInfo.data;

  const getTextStatus = (req: SpeeddatingRequest) => {
    if (req.status_evt === 'confirmed') return 'came';
    if (req.status_day === 'confirmed') return 'skipped';
    if ((req.status_reg === 'canceled') || (req.status_evt === 'canceled')) return 'waiting';
    return 'canceled';
  };

  return (visitorData && visitorData.user) ? (
    <div className="w-container">
      <h2>История пользователя {visitorData.user.display_name}</h2>

      {visitorInfo.state === 'fetching' ? (<>
        <p>Загрузка данных...</p>
      </>) : null}

      {visitorInfo.state === 'failed' ? (<>
        <Error error={visitorInfo.error}/>
      </>) : null}


      {visitorInfo.state === 'complete' ? (<>
        {visitorData.user.speeddating_requests?.map((req) => {
          const status = getTextStatus(req);
          return (
            <div className={cx({
              'text-red-700': status === 'skipped',
              'text-green-700': status === 'came',
              'text-gray-400': status === 'canceled',
            })}>
                            <span
                              className="pr-2">{moment(req.speeddating_event?.date).locale('ru').format('DD.MM.YYYY HH:mm')}</span>
              <span
                className="pr-2">{req.speeddating_event?.speeddating_cafe?.name} (id={req.speeddating_event?.id})</span>
              <span>
                            {status === 'came' ? (<>Успех</>) : null}
                {status === 'canceled' ? (<>Отмена</>) : null}
                {status === 'waiting' ? (<>Ожидается</>) : null}
                {status === 'skipped' ? (<>Прогул!</>) : null}
                        </span>
            </div>
          );
        })}
      </>) : null}
    </div>

  ) : (<div>Загрузка...</div>);
}


