import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UserListFilter } from '../types/spedddating';
import moment from 'moment';
import { fetchSpeeddatingPublicEvents, selectSpeeddatingPublicEvents } from '../reducers/spedddatingEvents';
import UserPreviewBig from '../components/UserPreviewBig';
import { Link } from 'react-router-dom';
import { getLoginState } from '../reducers/login';
import {
  performUserListQuery,
  selectManagerList,
  selectUserEditorList,
  selectUserEditorListQuery,
  userListQuerySet,
  userListReset,
} from '../reducers/userEditor';
import UserFilterForm from '../components/UserFilterForm';
import Error from '../components/error';
import _ManagerName from '../components/_ManagerName';
import { AppDispatch } from '../store';

// noinspection DuplicatedCode
export default function Cafes () {
  const dispatch = useDispatch<AppDispatch>();

  const events = useSelector(selectSpeeddatingPublicEvents);
  const userListQuery = useSelector(selectUserEditorListQuery);
  const loginState = useSelector(getLoginState);
  const userList = useSelector(selectUserEditorList);
  const managerList = useSelector(selectManagerList);

  if (events.state === 'unknown') {
    dispatch(fetchSpeeddatingPublicEvents());
  }

  const onReload = (filter: UserListFilter, page: number) => {
    let offset = Math.ceil(page * userListQuery.limit);
    dispatch(userListQuerySet({ filter, offset: offset, order: 'id' }));
    dispatch(performUserListQuery({ includeCallTable: 1 }));
  };

  useEffect(() => {
    // On reload: reset data
    dispatch(userListReset());
  }, []);

  useEffect(() => {
    if (userList.items && document.location.hash) {
      document.querySelector(document.location.hash)?.scrollIntoView();
    }
  }, [userList.items]);

  // ================================ <CAFES FORM> ================================

  const callStatusToReadable = (status: 'phone_off' | 'na' | 'denial' | 'agreement'): string => {
    if (status === 'phone_off') return 'Недоступен';
    else if (status === 'na') return 'Не отвечает';
    else if (status === 'denial') return 'Ответил (отказ)';
    else if (status === 'agreement') return 'Ответил (записан)';
    return 'Неизвестно';
  };
  const getVisitStatus = (status_day: string, status_evt: string): string => {
    if (status_evt === 'confirmed') return 'Пришел';
    else if (status_day === 'confirmed') return 'Прогулял';
    else return 'Отменил';
  };

  // ================================ </CAFES FORM> ===============================

  return (
    <>
      <h1>Исходящий звонок</h1>
      <UserFilterForm onUpdate={onReload} mode={'calls'} forceFilters={{ hidden_call: '0' }}>
        <div className="grid grid-rows-1 grid-cols-12 gap-4">
          <div className="col-start-1 col-end-13">
            <div className="w-container2">
              <h2 className={'mt-4 pb-0'}>Пользователи {userList.status === 'fetching' ? '(...обновление...)' : null}</h2>

              {userList.error !== '' ? (<>
                <Error error={userList.error}/>
              </>) : null}

              {userList.items.length > 0 ? userList.items.map(user => (
                <Link
                  to={`/manager/calls/${user.id}`}
                  state={{ prev: { pathname: document.location.pathname, search: document.location.search } }}
                  id={`call-user-${user.id}`}
                  key={user.id}>
                  <UserPreviewBig user={user} agency={!!loginState.admin_privilege.agency} buttons={<>
                    <Link
                      className={'btn mb-1 block text-center'}
                      to={`/manager/calls/${user.id}`}
                      state={{ prev: { pathname: document.location.pathname, search: document.location.search } }}>Позвонить</Link>
                    <Link className={'btn white mb-1 block text-center'} to={`/users/${user.id}/view`}>Профиль</Link>
                  </>} col2={
                    <div className={'text-sm'}>
                      <div className={'mb-2'}>
                        <div>Менеджер: <_ManagerName mgr_id={user.speeddating_manager_id}/></div>
                        <div>Визитов: {user.total_events_visited}; Прогулов: {user.total_events_skipped}</div>
                      </div>

                      {user.table ? user.table.slice(0, 5).map((record, id) => (<div key={id}>
                        {record.record_type === 'call' ? (<div>
                          <span className={'text-gray-500'}>{moment(record.date).format('DD.MM.YYYY HH:mm')}</span> [Зв.]
                          - {callStatusToReadable(record.status_call)}{record.comment ? `: ${record.comment}` : ''}
                        </div>) : (<div>
                          <span className={'text-gray-500'}>{moment(record.date).format('DD.MM.YYYY HH:mm')}</span> [Веч.]
                          - {getVisitStatus(record.status_day, record.status_evt)}{record.comment ? `: ${record.comment}` : ''}
                        </div>)}
                      </div>)) : (<>Нет таблицы</>)}
                    </div>
                  }/>
                </Link>
              )) : null}
            </div>
          </div>
        </div>
      </UserFilterForm>

    </>);
}
