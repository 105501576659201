import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { performValidate } from './reducers/login';

store.dispatch(performValidate());
store.subscribe(() => {
  const st = store.getState();
  if (st.login.status === 'idle') {
    store.dispatch(performValidate());
  }
});

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App/>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);
