import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { performUserGetById, selectUserEditorProfile } from '../reducers/userEditor';
import { useParams, useNavigate } from 'react-router-dom';
import Error from '../components/error';
import { UserEditable } from '../types/spedddating';
import { ttBirthAge } from '../types/toText';
import { AppDispatch } from '../store';

export default function ExportProfile () {
  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams() as { id: string };
  const navigate = useNavigate();

  const userEditorProfile = useSelector(selectUserEditorProfile);

  if (userEditorProfile.data?.id !== +id) {
    dispatch(performUserGetById({ id: +id }));
  }

  // Generate text profile
  const attitudeTo = [
    ['', 'Не задано'],
    ['-3', 'Резко негативное'],
    ['-2', 'Негативное'],
    ['-1', 'Слегка негативное'],
    ['0', 'Нейтральное'],
    ['1', 'Слегка позитивное'],
    ['2', 'Позитивное'],
    ['3', 'Резко позитивное'],
  ];
  const fields: Record<keyof UserEditable['user_profile'], { caption: string, type: 'text' | 'multiline' | 'enum' | 'boolean' | 'attitude' | 'todo', options?: string[][] }> = {
    height: {
      caption: 'Рост',
      type: 'text',
    },
    weight: {
      caption: 'Вес',
      type: 'text',
    },
    hair_color: {
      caption: 'Цвет волос',
      type: 'text',
    },
    eyes_color: {
      caption: 'Цвет глаз',
      type: 'text',
    },
    body_type: {
      caption: 'Телосложение',
      type: 'enum',
      options: [
        ['Обычный', 'Обычный'],
        ['Худой', 'Худой'],
        ['Спортивный', 'Спортивный'],
        ['Полный', 'Полный'],
        ['Очень большой', 'Очень большой'],
      ],
    },
    birth_location: {
      caption: 'Место рождения',
      type: 'text',
    },
    city: {
      caption: 'Город',
      type: 'text',
    },
    district: {
      caption: 'Район',
      type: 'text',
    },
    tattoo: {
      caption: 'Татуировки',
      type: 'boolean',
    },
    piercing: {
      caption: 'Пирсинг',
      type: 'boolean',
    },
    nationality: {
      caption: 'Национальность',
      type: 'text',
    },
    religion: {
      caption: 'Вероисповедание',
      type: 'text',
      options: [
        ['Христианство', 'Христианство'],
        ['Ислам', 'Ислам'],
        ['Буддизм', 'Буддизм'],
        ['Иудаизм', 'Иудаизм'],
        ['Атеизм', 'Атеизм'],
      ],
    },
    martial_status: {
      caption: 'Семейный статус',
      type: 'enum',
      options: [
        ['Cвободен', 'Cвободен'],
        ['В активном поиске', 'В активном поиске'],
        ['Готов к знакомствам', 'Готов к знакомствам'],
        ['Встречаюсь', 'Встречаюсь'],
        ['Женат', 'Женат'],
        ['В разводе', 'В разводе'],
        ['Вдовец', 'Вдовец'],
      ],
    },
    children: {
      caption: 'Наличие детей',
      type: 'text',
    },
    children_data: {
      caption: 'Дети',
      type: 'todo',
    },
    wait_children: {
      caption: 'Хочу детей',
      type: 'boolean',
    },
    housing_ownership: {
      caption: 'Наличие жилья',
      type: 'boolean',
    },
    housing_type: {
      caption: 'Тип жилья',
      type: 'enum',
      options: [
        ['Дом', 'Дом'],
        ['Квартира', 'Квартира'],
      ],
    },
    auto: {
      caption: 'Наличие автомобиля',
      type: 'boolean',
    },
    auto_model: {
      caption: 'Модель автомобиля',
      type: 'text',
    },
    driver_license: {
      caption: 'Водительские права',
      type: 'boolean',
    },
    cohabitant: {
      caption: 'С кем живу',
      type: 'enum',
      options: [
        ['Один', 'Один'],
        ['С родителями', 'С родителями'],
        ['Со второй половинкой', 'Со второй половинкой'],
        ['С друзьями', 'С друзьями'],
      ],
    },
    education: {
      caption: 'Образование',
      type: 'enum',
      options: [
        ['Среднее', 'Среднее'],
        ['Средне-специальное', 'Средне-специальное'],
        ['Студент', 'Студент'],
        ['Неполное высшее', 'Неполное высшее'],
        ['Высшее', 'Высшее'],
        ['Несколько высших', 'Несколько высших'],
        ['Научная степень', 'Научная степень'],
      ],
    },
    field_of_activity: {
      caption: 'Сфера деятельности',
      type: 'text',
      options: [
        ['Госслужба и некоммерческие организации', 'Госслужба и некоммерческие организации'],
        ['Банки и страховые компании', 'Банки и страховые компании'],
        ['Бухгалтерия и финансы предприятия', 'Бухгалтерия и финансы предприятия'],
        ['Торговля', 'Торговля'],
        ['Строительство и недвижимость', 'Строительство и недвижимость'],
        ['Искусство, творчество и развлечения', 'Искусство, творчество и развлечения'],
        ['Информационные технологии, интернет и телеком', 'Информационные технологии, интернет и телеком'],
        ['Маркетинг и реклама', 'Маркетинг и реклама'],
        ['Медицина и фармацевтика', 'Медицина и фармацевтика'],
        ['Наука и образование', 'Наука и образование'],
        ['Производство', 'Производство'],
        ['Рабочий персонал', 'Рабочий персонал'],
        ['Спортивные клубы и салоны красоты', 'Спортивные клубы и салоны красоты'],
        ['Туризм, рестораны, гостиницы', 'Туризм, рестораны, гостиницы'],
        ['Работа с персоналом', 'Работа с персоналом'],
        ['Юристы', 'Юристы'],
        ['Продажи', 'Продажи'],
        ['Транспорт и логистика', 'Транспорт и логистика'],
        ['Административный персонал', 'Административный персонал'],
        ['Домохозяйка', 'Домохозяйка'],
      ],
    },
    company_position: {
      caption: 'Позиция в компании',
      type: 'text',
    },
    own_business: {
      caption: 'Свой бизнес',
      type: 'boolean',
    },
    own_business_area: {
      caption: 'Сфера бизнеса',
      type: 'text',
    },
    income: {
      caption: 'Доход',
      type: 'text',
    },
    has_pets: {
      caption: 'Наличие питомцев',
      type: 'boolean',
    },
    pets: {
      caption: 'Какие питомцы',
      type: 'todo',
    },
    hobby: {
      caption: 'Хобби',
      type: 'text',
    },
    smoking: {
      caption: 'Курение',
      type: 'boolean',
    },
    foreign_languages: {
      caption: 'Владение иностранными языками',
      type: 'boolean',
    },
    foreign_languages_what: {
      caption: 'Языки',
      type: 'todo',
    },
    vegan: {
      caption: 'Вегетарианец',
      type: 'boolean',
    },
    attitude_to_art: {
      caption: 'Отношение к искусству',
      type: 'attitude',
    },
    attitude_to_travelling: {
      caption: 'Отношение к путешествиям',
      type: 'attitude',
    },
    attitude_to_pets: {
      caption: 'Отношение к питомцам',
      type: 'attitude',
    },
    attitude_to_sport: {
      caption: 'Отношение к спорту',
      type: 'attitude',
    },
    attitude_to_smoking: {
      caption: 'Отношение к курению',
      type: 'attitude',
    },
    attitude_to_alcohol: {
      caption: 'Отношение к алкоголю',
      type: 'attitude',
    },
    attitude_to_drugs: {
      caption: 'Отношение к наркотикам',
      type: 'attitude',
    },
    searching_for: {
      caption: 'Кого ищу',
      type: 'multiline',
    },
    about: {
      caption: 'Кого ищу',
      type: 'multiline',
    },

  };

  let arr: string[] = [];

  const [text, setText] = useState('');

  useEffect(() => {
    if (userEditorProfile.data && userEditorProfile.data.user_profile) {
      arr.push(`Имя: ${userEditorProfile.data.display_name}`);
      arr.push(`Дата рождения: ${ttBirthAge(userEditorProfile.data.birth, userEditorProfile.data.age, userEditorProfile.data.birth_year)}`);

      arr = [
        ...arr,
        ...Object.entries(userEditorProfile.data.user_profile)
          .filter(([key, val]) => val !== '')
          .filter(([key, val]) => ['id', 'user_id'].indexOf(key) === -1)
          .filter(([key, val]) => {
            // Filter out todos
            return !((key in fields) && fields[key as keyof UserEditable['user_profile']].type === 'todo');
          })
          .map(([key, val]) => {
            if (key in fields) {
              const field = fields[key as keyof UserEditable['user_profile']];
              if (field.type === 'boolean') {
                return `${field.caption}: ${val === '0' ? 'Нет' : val === '1' ? 'Да' : `Неизвестное значение ${val}`}`;
              }
              if (field.type === 'text') {
                return `${field.caption}: ${val}`;
              }
              if (field.type === 'multiline') {
                return `${field.caption}: ${val}`;
              }
              if (field.type === 'enum') {
                const filtered = field.options?.filter(([_val, _textValue]) => val === _val);
                if (!filtered || filtered.length !== 1) return `${field.caption}: Неизвестное значение ${val}`;
                return `${field.caption}: ${filtered[0][1]}`;
              }
              if (field.type === 'attitude') {
                const filtered = attitudeTo.filter(([_val, _textValue]) => val === _val);
                if (filtered.length !== 1) return `${field.caption}: Неизвестное значение ${val}`;
                return `${field.caption}: ${filtered[0][1]}`;
              }

              return `${fields[key as keyof UserEditable['user_profile']].caption}: Неизвестный тип ${val}`;
            }
            return `Неизвестный параметр ${key}: ${val}`;
          }),
      ];

      setText(arr.join('\n'));
    }
  }, [userEditorProfile.data]);


  return (<>
    {!userEditorProfile.data ? (<div className={'px-2 py-2 border-blue-600 bg-blue-200'}>
      Идет загрузка...
    </div>) : null}

    {userEditorProfile.error ? <Error error={userEditorProfile.error}/> : null}

    {userEditorProfile.data ? (<>
      <h1>Экспорт: {userEditorProfile.data.display_name}</h1>

      <div className={'mb-4'}>
        <button className={'btn'} onClick={() => {
          navigator.clipboard.writeText(text);
        }}>Скопировать
        </button>
        <button className={'btn white'} onClick={() => navigate(-1)}>Назад</button>
      </div>

      <textarea className={'w-full h-screen'} value={text} onChange={e => setText(e.target.value)}/>
    </>) : null}
  </>);
}
