import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpeeddatingRequestsForEvent, selectSpeeddatingDayRequests } from '../reducers/spedddatingRequests';
import { fetchSpeeddatingAllEvents, selectSpeeddatingAllEvents } from '../reducers/spedddatingEvents';
import VisitorCard from '../components/speeddatingManager/VisitorCard';
import { PHOTOS_BASE_URL } from '../app.config';
import moment from 'moment';
import { AppDispatch } from '../store';

export default function ManagerHistory () {

  const dispatch = useDispatch<AppDispatch>();
  const allEvents = useSelector(selectSpeeddatingAllEvents);
  const dayRequests = useSelector(selectSpeeddatingDayRequests);

  if (allEvents.wet) {
    dispatch(fetchSpeeddatingAllEvents());
  }

  const onSelectEvent = (eventId: number) => {
    dispatch(fetchSpeeddatingRequestsForEvent(eventId));
  };

  return (
    <>
      <h1>История вечеринок</h1>

      {/*<div className={"grid grid-cols-4 lg:grid-cols-12 gap-2"}>*/}
      {/*    <div className={"col-span-2"}>*/}
      {/*        <label>Дата - от</label>*/}
      {/*        <input type={"text"}/>*/}
      {/*    </div>*/}
      {/*    <div className={"col-span-2"}>*/}
      {/*        <label>Дата - до</label>*/}
      {/*        <input type={"text"}/>*/}
      {/*    </div>*/}
      {/*    <div className={"col-span-2"}>*/}
      {/*        <label>&nbsp;</label>*/}
      {/*        <button type={"submit"} className={"btn"}>OK (НЕ РАБОТАЕТ)</button>*/}
      {/*    </div>*/}
      {/*</div>*/}

      <div className={'grid grid-cols-6 lg:grid-cols-12 gap-2'}>
        <div className={'lg:col-start-7 lg:col-end-13 lg:row-start-1 col-span-6'}>
          <h2>Записи {dayRequests.state === 'fetching' ? '(...обновление...)' : null}</h2>

          <div className="grid grid-cols-2 gap-2">
            <div className="col-start-1 col-end-2">
              {dayRequests.items.filter(item => item.gender !== 'm').map((item) => (
                <VisitorCard key={item.id} req={item}/>
              ))}
            </div>
            <div className="col-start-2 col-end-3">
              {dayRequests.items.filter(item => item.gender === 'm').map((item) => (
                <VisitorCard key={item.id} req={item}/>
              ))}
            </div>
          </div>
        </div>
        <div className={'lg:col-start-1 lg:col-end-7 lg:row-start-1 col-span-6'}>
          <h2>Вечеринки</h2>
          {allEvents.items.map(event => (
            <div key={event.id} className={'w-container cursor-pointer flex'} onClick={() => onSelectEvent(event.id)}>
              <div className={'mr-2'}>
                {event.speeddating_cafe?.photos?.length ? (
                  <img className={'w-24'}
                       src={`${PHOTOS_BASE_URL}z${event.speeddating_cafe.photos[0].dir}/${event.speeddating_cafe.photos[0].thumb}`}
                       alt={''}/>
                ) : (
                  <div className={'square bg-gray-400 w-24'}/>
                )}
              </div>

              <div className={'grid grid-cols-12 gap-2 w-full'}>
                <div className={'lg:col-span-8 col-span-12'}>
                  <div className={'font-bold'}>{event.speeddating_cafe?.name}</div>
                  <div className={''}>{event.speeddating_cafe?.address}</div>
                  <div className={''}>{moment(event.date).format('dd, DD.MM.YYYY HH:mm')}</div>
                </div>
                <div className={'lg:col-span-4 col-span-12'}>
                  <div><span
                    className={'text-gray-500'}>Записано:</span> {event.speeddating_requests?.filter(req => (req.status_evt !== 'confirmed') || ((req.status_reg !== 'canceled') && (req.status_day !== 'canceled'))).length}
                  </div>
                  <div><span
                    className={'text-gray-500'}>Пришло:</span> {event.speeddating_requests?.filter(req => (req.status_evt !== 'confirmed')).length}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>


      </div>

    </>
  );
}
