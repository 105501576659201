import { combineReducers } from 'redux';

import loginReducer from './login';
import speeddatingRequestReducer from './spedddatingRequests';
import speeddatingEventReducer from './spedddatingEvents';
import usersReducer from './users';
import cafeReducer from './cafes';
import callReducer from './calls';
import userEditor from './userEditor';
import payments from './payments';
import managerCallStats from './managerCallStats';
import expoReducer from './expo';
import regionsReducer from './regions';
import adminReducer from './admin';

export default combineReducers({
  login: loginReducer,
  speeddating_request: speeddatingRequestReducer,
  speeddating_event: speeddatingEventReducer,
  users: usersReducer,
  cafes: cafeReducer,
  calls: callReducer,
  userEditor: userEditor,
  payments: payments,
  managerCallStats: managerCallStats,
  expo: expoReducer,
  regions: regionsReducer,
  admin: adminReducer,
});
