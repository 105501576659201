import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios from 'axios';
import { BASE_URL } from '../app.config';
import { ApiError } from '../types/spedddating';
import { TAdmin } from '../types/api';
import { TApiAnswer, isApiSuccessAnswer } from './helpers';


const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};

type TAdminState = {
  data: TAdmin[];
  loading: boolean;
  initialized: boolean;
  error: string | null;
};

// Initial state
const initialState: TAdminState = {
  data: [],
  loading: false,
  initialized: false,
  error: null,
};


export const performAdminListFetch = createAsyncThunk(
  'admin/get',
  async () => {
    const result = await axios<TApiAnswer<TAdmin[]>>(`${BASE_URL}/admin`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    const { data } = result;

    if (isApiSuccessAnswer(data)) {
      return data.response;
    }

    throw apiErrorHandler(data);
  },
);

export const performAdminListSave = createAsyncThunk(
  'admin/set',
  async (adminData: TAdmin, { dispatch }) => {

    const data = {
      access_km: Number(adminData.access_km),
      access_kv: Number(adminData.access_kv),
      access_cafes: Number(adminData.access_cafes),
      access_facecontrol: Number(adminData.access_facecontrol),
      access_useredit: Number(adminData.access_useredit),
      access_stats: Number(adminData.access_stats),
      access_agency: Number(adminData.access_agency),
      access_agency_phones: Number(adminData.access_agency_phones),
      access_finance: Number(adminData.access_finance),
      name: adminData.name,
      regions: adminData.regions,
    }

    const result = await axios<TApiAnswer<TAdmin>>(`${BASE_URL}/admin/by-id/${adminData.id}`, {
      method: 'put',
      data,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch(apiErrorHandler);


    const { data: res } = result;

    if (isApiSuccessAnswer(res)) {
      dispatch(performAdminListFetch());
      return res.response;
    }

    throw apiErrorHandler(res);
  },
);

export const performAdminDelete = createAsyncThunk(
  'admin/delete',
  async (adminData: Pick<TAdmin, 'id'>, { dispatch }) => {

    const result = await axios<TApiAnswer<TAdmin>>(`${BASE_URL}/admin/by-id/${adminData.id}`, {
      method: 'delete',
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    }).catch(apiErrorHandler);

    const { data: res } = result;

    if (isApiSuccessAnswer(res)) {
      dispatch(performAdminListFetch());
      return res.response;
    }

    throw apiErrorHandler(res);
  },
);


export const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(performAdminListFetch.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(performAdminListFetch.fulfilled, (state, action: PayloadAction<TAdmin[]>) => {
        state.initialized = true;
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(performAdminListFetch.rejected, (state, action) => {
        state.initialized = true;
        state.loading = false;
        state.error = JSON.stringify(action.error, null, 2);
      });

    builder
      .addCase(performAdminListSave.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(performAdminListSave.fulfilled, (state, action) => {
        state.loading = false;
        state.error = '';

        // Обновляем данные конкретного администратора
        const updatedAdmin = action.payload;
        state.data = state.data.map((admin) =>
          admin.id === updatedAdmin.id
            ? { ...updatedAdmin, hasChanges: false } // Сбрасываем флаг hasChanges
            : admin,
        );
      })
      .addCase(performAdminListSave.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.stringify(action.error, null, 2);
      });

    builder
      .addCase(performAdminDelete.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(performAdminDelete.fulfilled, (state, action) => {
        state.loading = false;
        state.error = '';
      })
      .addCase(performAdminDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = JSON.stringify(action.error, null, 2);
      });
  },
});

export const selectAdminList = (state: RootState) => state.admin;

export default adminsSlice.reducer;
