import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axios, { Method } from 'axios';
import { BASE_URL } from '../app.config';
import { ApiError, SpeeddatingCafe, speeddatingCafeObject, SpeeddatingEvent, speeddatingEventObject } from '../types/spedddating';
import moment from 'moment';


const apiErrorHandler = (error: any) => {
  if (error.response && error.response.data) {
    if (error.response.data.ok !== undefined && error.response.data.message !== undefined && error.response.data.error !== undefined) {
      throw new ApiError(error.response.data);
    }
  }

  throw error;
};


interface CafesReduxInitState {
  cafes: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    items: Array<SpeeddatingCafe>,
    error: string,
    by_id: any,
  },
  events: {
    status: 'idle' | 'fetching' | 'complete' | 'failed',
    items: Array<SpeeddatingEvent>,
    error: string
  },
  cafesEditor: {
    status: 'disabled' | 'editing' | 'committing' | 'failed',
    error: string,
    id: number,
    data: SpeeddatingCafe,
  },
  eventsEditor: {
    status: 'disabled' | 'editing' | 'committing' | 'failed',
    error: string,
    id: number,
    data: SpeeddatingEvent,
  },
}

const initialState: CafesReduxInitState = {
  cafes: {
    status: 'idle',
    items: [],
    error: '',
    by_id: {},
  },
  events: {
    status: 'idle',
    items: [],
    error: '',
  },
  cafesEditor: {
    status: 'disabled',
    error: '',
    id: 0,
    data: { ...speeddatingCafeObject },
  },
  eventsEditor: {
    status: 'disabled',
    error: '',
    id: 0,
    data: { ...speeddatingEventObject },
  },
};

// ------------------------------------------------- CAFES -------------------------------------------------------------

export const fetchSpeeddatingCafes = createAsyncThunk(
  'speeddating/cafe/list',
  async () => {
    const result = await axios(`${BASE_URL}/speeddating/cafe`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

export const performSpeeddatingCafeUpdate = createAsyncThunk(
  'speeddating/cafe/set',
  async (r: { id: number, data: SpeeddatingCafe }, thunkAPI) => {
    const req = {
      new: { method: 'post', url: `${BASE_URL}/speeddating/cafe` },
      edit: { method: 'put', url: `${BASE_URL}/speeddating/cafe/${r.id}` },
    };

    const action = r.id === 0 ? 'new' : 'edit';

    const result = await axios(req[action].url, {
      method: (req[action].method as Method),
      withCredentials: true,
      data: {
        name: r.data.name,
        address: r.data.address,
        metro: r.data.metro,
        map: r.data.map,
        description: '<p>' + r.data.description.replace('\n\n', '</p><p>') + '</p>',
        how_to_pass: '<p>' + r.data.how_to_pass.replace('\n\n', '</p><p>') + '</p>',
      },
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(fetchSpeeddatingCafes());

    return result.data;
  },
);

export const performSpeeddatingCafeAddPhoto = createAsyncThunk(
  'speeddating/cafe/addPhoto',
  async (r: { cafeId: number, photo: any }) => {
    const form = new FormData();
    form.append('file', r.photo);

    const result = await axios.post(`${BASE_URL}/speeddating/cafe/${r.cafeId}/photo`, form, {
      headers: { 'content-type': 'multipart/form-data' },
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);
export const performSpeeddatingCafeDeletePhoto = createAsyncThunk(
  'speeddating/cafe/addPhoto',
  async (r: { cafeId: number, photoId: number }) => {
    const result = await axios(`${BASE_URL}/speeddating/cafe/${r.cafeId}/photo/${r.photoId}`, {
      method: 'delete',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);

// ------------------------------------------------ EVENTS -------------------------------------------------------------

export const fetchSpeeddatingEvents = createAsyncThunk(
  'speeddating/event/list',
  async () => {
    const result = await axios(`${BASE_URL}/speeddating/event/list`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    return result.data;
  },
);
export const performSpeeddatingEventUpdate = createAsyncThunk(
  'speeddating/event/set',
  async (r: { id: number, data: SpeeddatingEvent }, thunkAPI) => {
    const req = {
      new: { method: 'post', url: `${BASE_URL}/speeddating/event` },
      edit: { method: 'put', url: `${BASE_URL}/speeddating/event/${r.id}` },
    };

    const action = r.id === 0 ? 'new' : 'edit';

    const result = await axios(req[action].url, {
      method: (req[action].method as Method),
      withCredentials: true,
      data: {
        speeddating_cafe_id: r.data.speeddating_cafe_id,
        date: moment.utc(r.data.date).subtract(3 * 60, 'minutes').toISOString(false),
        description: r.data.description,
        old_price_m: r.data.old_price_m,
        old_price_f: r.data.old_price_f,
        price_m: r.data.price_m,
        price_f: r.data.price_f,
        age_group: r.data.age_group,
        min_age_m: r.data.min_age_m,
        max_age_m: r.data.max_age_m,
        min_age_f: r.data.min_age_f,
        max_age_f: r.data.max_age_f,
        info: r.data.info,
        status: r.data.status,
      },
    }).catch(apiErrorHandler);

    thunkAPI.dispatch(fetchSpeeddatingEvents());

    return result.data;
  },
);

export const counterSlice = createSlice({
  name: 'cafe',
  initialState,
  reducers: {
    cafeEdit: ((state, action: PayloadAction<{ id: number, data: SpeeddatingCafe }>) => {
      state.cafesEditor = {
        status: 'editing',
        error: '',
        id: action.payload.id,
        data: { ...action.payload.data },
      };
    }),
    cafeEditReset: (state) => {
      state.cafesEditor = {
        status: 'disabled',
        error: '',
        id: 0,
        data: { ...speeddatingCafeObject },
      };
    },
    eventEdit: ((state, action: PayloadAction<{ id: number, data: SpeeddatingEvent }>) => {
      state.eventsEditor = {
        status: 'editing',
        error: '',
        id: action.payload.id,
        data: { ...action.payload.data },
      };
    }),
    eventEditReset: (state) => {
      state.eventsEditor = {
        status: 'disabled',
        error: '',
        id: 0,
        data: { ...speeddatingEventObject },
      };
    },
    eventEditSet: (state, action: PayloadAction<{ id: number }>) => {
      state.eventsEditor.id = action.payload.id;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSpeeddatingCafes.pending, state => {
      // console.log(Date.now(), "fetchSpeeddatingCafes: pending");
      state.cafes.status = 'fetching';
    });

    builder.addCase(fetchSpeeddatingCafes.fulfilled, (state, action) => {
      // console.log(Date.now(), "fetchSpeeddatingCafes: fulfilled");
      if (state.cafes.status !== 'fetching') return;
      state.cafes.status = 'complete';
      state.cafes.by_id = {} as any;
      state.cafes.items = action.payload.response.map((cafe: SpeeddatingCafe) => {
        state.cafes.by_id[cafe.id.toString()] = cafe;
        return {
          ...cafe,
          description: cafe.description.replaceAll('<p>', '').replaceAll('</p>', '\n\n').trim(),
          how_to_pass: cafe.how_to_pass.replaceAll('<p>', '').replaceAll('</p>', '\n\n').trim(),
        };
      });
    });


    builder.addCase(fetchSpeeddatingCafes.rejected, (state, action) => {
      if (state.cafes.status !== 'fetching') return;
      state.cafes.status = 'failed';
      state.cafes.error = JSON.stringify(action.error, null, 2);
    });

    builder.addCase(performSpeeddatingCafeUpdate.pending, state => {
      // console.log(Date.now(), "performSpeeddatingCafeUpdate: pending");
      state.cafesEditor.status = 'committing';
    });
    builder.addCase(performSpeeddatingCafeUpdate.fulfilled, (state, action) => {
      // console.log(Date.now(), "performSpeeddatingCafeUpdate: fulfilled");
      state.cafesEditor = {
        status: 'disabled',
        error: '',
        id: 0,
        data: { ...speeddatingCafeObject },
      };
    });
    builder.addCase(performSpeeddatingCafeUpdate.rejected, (state, action) => {
      state.cafesEditor.status = 'failed';
      state.cafesEditor.error = JSON.stringify(action.error, null, 2);
    });

    // TODO: photos!

    // ------------------------------------------- EVENTS ----------------------------------------------------------

    builder.addCase(fetchSpeeddatingEvents.pending, (state) => {
      state.events.status = 'fetching';
    });
    builder.addCase(fetchSpeeddatingEvents.fulfilled, (state, action) => {
      if (state.events.status !== 'fetching') return;
      state.events.status = 'complete';
      state.events.items = action.payload.response.map((event: SpeeddatingEvent) => ({
        ...event,
        date: moment(event.date).utcOffset(3 * 60).format('YYYY-MM-DD HH:mm'),
      }));

    });
    builder.addCase(fetchSpeeddatingEvents.rejected, (state, action) => {
      if (state.events.status !== 'fetching') return;
      state.events.status = 'failed';
      state.events.error = JSON.stringify(action.error, null, 2);
    });

    builder.addCase(performSpeeddatingEventUpdate.pending, (state) => {
      state.eventsEditor.status = 'committing';
    });
    builder.addCase(performSpeeddatingEventUpdate.fulfilled, (state, action) => {
      state.eventsEditor = {
        status: 'disabled',
        error: '',
        id: 0,
        data: { ...speeddatingEventObject },
      };
    });
    builder.addCase(performSpeeddatingEventUpdate.rejected, (state, action) => {
      state.eventsEditor.status = 'failed';
      state.eventsEditor.error = JSON.stringify(action.error, null, 2);
    });

  },
});

export const selectCafes = (state: RootState) => state.cafes.cafes;
export const selectCafeEditor = (state: RootState) => state.cafes.cafesEditor;
export const { cafeEdit, cafeEditReset } = counterSlice.actions;

export const selectEvents = (state: RootState) => state.cafes.events;
export const selectEventEditor = (state: RootState) => state.cafes.eventsEditor;
export const { eventEdit, eventEditReset, eventEditSet } = counterSlice.actions;

export default counterSlice.reducer;
