import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../app.config';
import { apiErrorHandler, TApiAnswer, isApiSuccessAnswer } from './helpers';
import { RootState } from '../store';
import { TRegion } from '../types/api'; // Adjust the path to your store

type RegionsState = {
  data: TRegion[];
  loading: boolean;
  initialized: boolean;
  error: string | null;
};

// Initial state
const initialState: RegionsState = {
  data: [],
  loading: false,
  initialized: false,
  error: null,
};

// Fetch regions
export const fetchRegions = createAsyncThunk<TRegion[], void, { rejectValue: string }>(
  'regions/fetchRegions',
  async () => {

    const result = await axios<TApiAnswer<TRegion[]>>(`${BASE_URL}/region`, {
      method: 'get',
      withCredentials: true,
    }).catch(apiErrorHandler);

    if (isApiSuccessAnswer(result.data)) {
      return result.data.response;
    }

    throw apiErrorHandler(result.data);
  },
);

const regionsSlice = createSlice({
  name: 'regions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRegions.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchRegions.fulfilled, (state, action: PayloadAction<TRegion[]>) => {
        state.initialized = true;
        state.loading = false;
        state.data = action.payload;
      })
      .addCase(fetchRegions.rejected, (state, action) => {
        state.initialized = true;
        state.loading = false;
        state.error = JSON.stringify(action.error, null, 2);
      });
  },
});

// Selector
export const selectRegions = (state: RootState) => state.regions;

// Export reducer
export default regionsSlice.reducer;
