import React, { useState } from 'react';
import { User } from '../../types/spedddating';
import { performUserSearch, resetUserSearchResults, selectUserSearchResult } from '../../reducers/users';
import { setUser } from '../../reducers/spedddatingRequests';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import Error from '../error';
import { AppDispatch } from '../../store';

export default function UserSearchForm () {
  const dispatch = useDispatch<AppDispatch>();

  const userSearchResult = useSelector(selectUserSearchResult);

  const { register, handleSubmit, watch, formState: { errors }, clearErrors } = useForm<User>();
  const _tel = watch('tel', '');

  const [tel, setTel] = useState('');
  if (tel !== _tel) {
    if (_tel.length >= 3)
      dispatch(performUserSearch({ tel: _tel }));
    else
      dispatch(resetUserSearchResults());
    setTel(_tel);
  }

  const onSubmit: SubmitHandler<User> = (data) => {
    dispatch(setUser(data));
  };
  const onReset = () => {
    clearErrors();
    dispatch(resetUserSearchResults());
  };
  const onFound = (item: User) => {
    dispatch(setUser(item));
  };

  return (
    <div className="w-container">
      <h2>Создание / поиск пользователя</h2>

      <form onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-9">
            <label htmlFor="req_ed_name">Имя</label>
            <input id="req_ed_name" type="text" {...register('display_name', { required: true })} />
          </div>

          <div className="col-span-3">
            <div className="col-span-12">
              <label htmlFor="gender">Пол</label>
              <select id="gender"
                      className={errors.gender ? 'error' : ''}
                      {...register('gender', {
                        validate: (val) => val !== 'u',
                      })}>
                <option value="u">Не задан</option>
                <option value="m">М</option>
                <option value="f">Ж</option>
              </select>
            </div>
          </div>

          <div className="col-span-12">
            <label htmlFor="req_ed_tel">Телефон (+поиск)</label>
            <input id="req_ed_tel" type="text" className={errors.tel ? 'error' : ''} {...register('tel', {
              required: true,
              pattern: /^7(\d{10})/,
            })}/>
            {errors.tel ? (<div className={'text-red-600 text-sm'}>
              Телефон должен начинаться с 7 и содержать 11 цифр например 79035551122
            </div>) : null}
          </div>

          <div className="col-span-12">
            <label htmlFor="req_ed_email">E-mail</label>
            <input id="req_ed_email" type="text" {...register('email')}/>
          </div>

          <div className="col-span-12">
            <label htmlFor="req_ed_email">Возраст</label>
            <input id="req_ed_email" type="text" className={errors.age ? 'error' : ''} {...register('age', {
              required: true,
              pattern: /^[0-9]{2,}$/,
            })}/>
          </div>

        </div>
        <div className="px-4 py-3 text-right">
          <button className="btn white" type="reset">Сбросить</button>
          <button className="btn" type="submit">Создать</button>
        </div>
      </form>

      {userSearchResult.state !== 'idle' ? (<>
        <h2>Поиск</h2>

        {userSearchResult.state === 'fetching' ? (<>
          <p>Загрузка данных...</p>
        </>) : null}

        {userSearchResult.state === 'failed' ? (<>
          <Error error={userSearchResult.error}/>
        </>) : null}

        {userSearchResult.state === 'complete' ? (<div>
          {userSearchResult.items.map((item) => (
            <div className="text-indigo-800 cursor-pointer hover:underline" onClick={() => onFound(item)}>
              +{item.tel} {item.display_name}
            </div>))}
        </div>) : null}
      </>) : null}

    </div>
  );
}


