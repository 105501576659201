import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-datepicker/dist/react-datepicker.css';

import {
  fetchSpeeddatingCafes,
  fetchSpeeddatingEvents,
  performSpeeddatingEventUpdate,
  eventEdit,
  eventEditReset,
  eventEditSet,
  selectCafes,
  selectEvents,
  selectEventEditor,
} from '../reducers/cafes';
import { useForm } from 'react-hook-form';
import { SpeeddatingEvent } from '../types/spedddating';
import moment from 'moment';
import Error from '../components/error';
import { getLoginState } from '../reducers/login';
import { AppDispatch } from '../store';

const ageGroup = {
  '1': 'Младшая группа',
  '2': 'Основная группа',
  '3': 'Старшая группа',
  '4': 'Группа 50+',
  '5': 'ВИП мероприятия',
};

export default function Events () {
  const dispatch = useDispatch<AppDispatch>();

  const loginState = useSelector(getLoginState);
  const cafes = useSelector(selectCafes);
  const events = useSelector(selectEvents);
  const eventEditor = useSelector(selectEventEditor);

  if (cafes.status === 'idle') {
    dispatch(fetchSpeeddatingCafes());
  }
  if (events.status === 'idle') {
    dispatch(fetchSpeeddatingEvents());
  }

  // ================================ <CAFES FORM> ================================

  const { register, handleSubmit, setValue } = useForm<SpeeddatingEvent>();

  useEffect(() => {
    // Update form from redux store
    if (eventEditor.data && (eventEditor.status === 'editing' || eventEditor.status === 'disabled')) {
      // @ts-ignore
      (Object.keys(eventEditor.data) as Array<keyof SpeeddatingEvent>).forEach(key => setValue(key, eventEditor.data[key]));
    }
  }, [eventEditor, setValue]);

  const onEventSubmit = (data: SpeeddatingEvent) => {
    dispatch(performSpeeddatingEventUpdate({ id: eventEditor.id, data: data }));
  };
  const onEventReset = () => {
    dispatch(eventEditReset());
  };
  const onClone = () => {
    dispatch(eventEditSet({ id: 0 }));
  };

  const onEditEvent = (id: number, data: SpeeddatingEvent) => {
    dispatch(eventEdit({ id, data }));
  };

  // ================================ </CAFES FORM> ===============================

  if (!loginState.admin_privilege.cafes) return (<>Вам сюда нельзя</>);

  return (
    <>
      <h1>Редактор вечеринок</h1>

      <div className="grid grid-rows-1 grid-cols-12 gap-4">
        <div className="col-start-1 col-end-6">
          <div className="w-container">
            {eventEditor.id === 0 ? (
              <h2>Создание вечеринки</h2>
            ) : (
              <h2>Редактирование вечеринки</h2>
            )}

            <form onSubmit={handleSubmit(onEventSubmit)} onReset={() => onEventReset()}>
              <div className="grid grid-cols-2 gap-2">

                <div className="col-span-2">
                  <label htmlFor="speeddating_cafe_id">Кафе</label>
                  <select id="speeddating_cafe_id" {...register('speeddating_cafe_id')}>
                    <option value="0">Выберите</option>
                    {cafes.items.map(cafe => (
                      <option key={cafe.id}
                              value={cafe.id}>{cafe.name}, {cafe.address}</option>
                    ))}
                  </select>
                </div>

                <div className="col-span-2">
                  <label htmlFor="date">Дата/время</label>
                  <input id="date" type="text" {...register('date')}/>
                </div>

                <div className="col-span-2">
                  <label htmlFor="status">Статус</label>
                  <select id="info"{...register('status')}>
                    <option value="hidden">Скрыта</option>
                    <option value="published">Опубликована</option>
                    <option value="canceled">Отменена</option>
                  </select>
                </div>

                <div className="col-span-2">
                  <label htmlFor="age_group">Возрастная группа</label>
                  <select id="age_group" {...register('age_group')}>
                    {Object.entries(ageGroup).map(([key, value]) => (
                      <option key={key} value={key}>{value}</option>
                    ))}
                  </select>
                </div>

                <div className="col-span-2">
                  <label htmlFor="description">Описание</label>
                  <input id="description" type="text" {...register('description')}/>
                </div>

                <div className="col-span-2">
                  <label htmlFor="info">Доп.информация</label>
                  <input id="info" type="text" {...register('info')}/>
                </div>

                <div className="col-start-1 col-end-2">
                  <label htmlFor="old_price_m">Старая цена М</label>
                  <input id="old_price_m" type="text" {...register('old_price_m')}/>
                </div>
                <div className="col-start-2 col-end-3">
                  <label htmlFor="old_price_f">Старая цена Ж</label>
                  <input id="old_price_f" type="text" {...register('old_price_f')}/>
                </div>

                <div className="col-start-1 col-end-2">
                  <label htmlFor="price_m">Цена М</label>
                  <input id="price_m" type="text" {...register('price_m')}/>
                </div>
                <div className="col-start-2 col-end-3">
                  <label htmlFor="price_f">Цена Ж</label>
                  <input id="price_f" type="text" {...register('price_f')}/>
                </div>


                <div className="col-start-1 col-end-2">
                  <label htmlFor="min_age_m">Возраст М: от</label>
                  <input id="min_age_m" type="text" {...register('min_age_m')}/>
                </div>
                <div className="col-start-2 col-end-3">
                  <label htmlFor="min_age_f">Возраст Ж: от</label>
                  <input id="min_age_f" type="text" {...register('min_age_f')}/>
                </div>

                <div className="col-start-1 col-end-2">
                  <label htmlFor="max_age_m">Возраст М: до</label>
                  <input id="max_age_m" type="text" {...register('max_age_m')}/>
                </div>
                <div className="col-start-2 col-end-3">
                  <label htmlFor="max_age_f">Возраст Ж: до</label>
                  <input id="max_age_f" type="text" {...register('max_age_f')}/>
                </div>

              </div>

              <div className="px-4 py-3 text-right">
                {eventEditor.id === 0 ? null : (
                  <button className="btn white" onClick={() => onClone()}>Клонировать</button>
                )}

                <input className="btn white" type="reset"
                       value={eventEditor.id === 0 ? 'Сбросить' : 'Отменить ред.'}/>
                <input className="btn" type="submit" disabled={eventEditor.status === 'committing'}
                       value={eventEditor.id === 0 ? 'Создать' : 'Сохранить'}/>
              </div>
            </form>

          </div>
        </div>

        <div className="col-start-6 col-end-13">
          <div className="w-container">
            <h2>Вечеринки</h2>

            {events.status === 'fetching' ? (<>
              Идет загрузка...
            </>) : null}

            {events.status === 'failed' ? (<>
              <Error error={events.error}/>
            </>) : null}

            {events.status === 'complete' ? (<>
              {events.items.map((event) => (
                <div key={event.id} className="w-blk px-2" onClick={() => onEditEvent(event.id, event)}>
                  <div></div>
                  <div>
                    <span className={'font-bold pr-2'}>{moment(event.date).format('ddd DD.MM.YYYY HH:mm')}</span>
                    {cafes.by_id[event.speeddating_cafe_id]?.name || 'Неизвестное кафе'}
                  </div>
                  <div>
                    <span
                      className={'pr-2'}>{event.age_group === 0 ? 'Группа не задана' : ageGroup[event.age_group.toString() as '1' | '2' | '3' | '4']}</span>
                    (М:{event.min_age_m}-{event.max_age_m}; Ж:{event.min_age_f}-{event.max_age_f})
                  </div>
                </div>))}
            </>) : null}
          </div>
        </div>

      </div>
    </>);
}
